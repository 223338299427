import React, { useEffect, useRef, useState } from "react";
import './signup.css';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { getSignUpPageData, updateValidationCheck, updateLoading } from "../../actions/signupAction";
import { checkRegistrationValidate } from "../../api/signupApi";
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import toastr from 'toastr';
import ReCAPTCHA from "react-google-recaptcha";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

let currentTab = 0;

const SignUpForm = props => {
    document.documentElement.style.backgroundColor = props.agencyThemeColor;
    const [aboutUsItems, setAboutUsItems] = useState(props.aboutUsItems);
    const [companyCategories, setCompanyCategories] = useState(props.companyCategories);
    const [companyName, setCompanyName] = useState('');
    const [companyCategory, setCompanyCategory] = useState('');
    const [fullName, setFullName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [aboutUsItem, setAboutUsItem] = useState('');
    const [requiredFieldsAlert, setRequiredFieldsAlert] = useState(false);
    const [buttonText, setButtonText] = useState('Next');

    const recaptchaRef = useRef(null);

    useEffect(() => {
        setAboutUsItems(props.aboutUsItems);
    }, [props.aboutUsItems]);

    useEffect(() => {
        setCompanyCategories(props.companyCategories);
    }, [props.companyCategories]);

    useEffect(() => {
        showTab(currentTab);
    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const showTab = n => {
        var x = document.getElementsByClassName("tab");
        x[n].style.display = "block";
        // ... and fix the Previous/Next buttons:
        if (n == 0) {
            document.getElementById("prevBtn").style.display = "none";
        } else {
            document.getElementById("prevBtn").style.display = "inline";
        }
        if (n == (x.length - 1)) {
            setButtonText('Submit');
        } else {
            setButtonText(<>Next  <NavigateNextIcon /></>);
        }
        // ... and run a function that displays the correct step indicator:
        fixStepIndicator(n)
    }

    const fixStepIndicator = n => {
        // This function removes the "active" class of all steps...
        var i, x = document.getElementsByClassName("step");
        for (i = 0; i < x.length; i++) {
            x[i].className = x[i].className.replace(" active", "");
        }
        //... and adds the "active" class to the current step:
        x[n].className += " active";
    }

    const nextPrev = n => {

        setRequiredFieldsAlert(false);
        if (currentTab == 0) {

            if (fullName === '' || phoneNumber === '' || email === '') {
                setRequiredFieldsAlert(true);

                return false;
            }

            if (!validateEmail(email)) {
                toastr.error('Email is not valid');

                return false;
            }

            let formData = new FormData();
            formData.append('company_name', 'companyName');
            formData.append('name', fullName);
            formData.append('phone', phoneNumber);
            formData.append('email', email);
            formData.append('hear_about_us_item_id', 'aboutUsItem');
            formData.append('company_category_id', 'companyCategory');
            formData.append('agency_id', props.agencyId);

            checkRegistrationValidate(formData).then(response => {

                updateLoading(false);
                if (response.data.status === 'success') {

                } else {
                    let errors = [];
                    for (const [key, value] of Object.entries(response.data.html)) {
                        errors.push(`${key}: ${value}`);
                    }

                    nextPrev(- 1);
                    // currentTab = x.length - 1;
                    // x[x.length - 1].style.display = "block";
                    toastr.error(errors);
                    return true;
                }
            });
        }

        // This function will figure out which tab to display
        var x = document.getElementsByClassName("tab");
        // Exit the function if any field in the current tab is invalid:
        if ((n == 1 || n == 2 || n == 3) && !validateForm()) {
            setRequiredFieldsAlert(true);

            return false;
        }

        // Hide the current tab:
        if (typeof x[currentTab] === 'undefined') {
            x[currentTab - 1].style.display = "block";
        } else {
            x[currentTab].style.display = "none";
        }

        // Increase or decrease the current tab by 1:
        currentTab = currentTab + n;
        // if you have reached the end of the form... :
        if (currentTab >= x.length) {
            //...the form gets submitted:
            updateLoading(true);

            let formData = new FormData();
            formData.append('company_name', companyName);
            formData.append('name', fullName);
            formData.append('phone', phoneNumber);
            formData.append('email', email);
            formData.append('hear_about_us_item_id', aboutUsItem);
            formData.append('company_category_id', companyCategory);
            formData.append('agency_id', props.agencyId);

            checkRegistrationValidate(formData).then(response => {

                updateLoading(false);
                if (response.data.status === 'success') {
                    props.updateValidationCheck({
                        validationCheck: true,
                        companyName: companyName,
                        fullName: fullName,
                        email: email,
                        phoneNumber: phoneNumber,
                        aboutUsItem: aboutUsItem,
                        companyCategory: companyCategory,
                        signupType: props.signupType
                    });
                } else {
                    let errors = [];
                    for (const [key, value] of Object.entries(response.data.html)) {
                        errors.push(`${key}: ${value}`);
                    }

                    nextPrev(- 1);
                    // currentTab = x.length - 1;
                    // x[x.length - 1].style.display = "block";
                    toastr.error(errors);
                }
            });
            // document.getElementById("regForm").submit();

            return false;
        }

        // Otherwise, display the correct tab:
        showTab(currentTab);
    }

    const validateForm = () => {
        // This function deals with validation of the form fields
        var x, y, i, valid = true;
        x = document.getElementsByClassName("tab");
        y = x[currentTab].getElementsByTagName("input");
        // A loop that checks every input field in the current tab:
        for (i = 0; i < y.length; i++) {
            // If a field is empty...
            if (y[i].value === "") {
                // add an "invalid" class to the field:
                y[i].className += " invalid";
                // and set the current valid status to false:
                valid = false;
            }
        }
        // If the valid status is true, mark the step as finished and valid:
        if (valid) {
            document.getElementsByClassName("step")[currentTab].className += " finish";
        }
        return valid; // return the valid status
    }

    const renderCompanyCategory = () => {
        let categoryOptions = [];
        if (companyCategories.length) {
            companyCategories.forEach((row, index) => {
                categoryOptions.push(
                    <MenuItem value={row.id}>{row.title}</MenuItem>
                );
            });
            return categoryOptions;
        }
    }

    const renderAboutItems = () => {
        let aboutItems = [];
        if (aboutUsItems.length) {
            aboutUsItems.forEach((row, index) => {
                aboutItems.push(
                    <MenuItem value={row.id}>{row.title}</MenuItem>
                );
            });
            return aboutItems;
        }
    }

    const checkLetterExistInText = (text) => {
        var regExp = /[a-zA-Z]/g;
        if (!regExp.test(text)) {
            setPhoneNumber(text);
        }
    }

    const validateEmail = (email) => {
        //eslint-disable-next-line
        let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
        if (email.match(regexEmail)) {
            return true;
        } else {
            return false;
        }
    }


    return (
        <React.Fragment>
            <div className="prgoress_bar">
                <span className="step"></span>
                <span className="step"></span>
                <span className="step"></span>
                <span className="step"></span>
            </div>
            <div className="topbar" style={{ background: props.agencyThemeColor, color: props.agencyTextColor }}>
                <div className="row">
                    <div className="topbar_content top__bar__content__v2 ">
                        <div className="col s12 m12 l12 lognin_link">
                            <div className="text text_v2">
                                <p>
                                    Already Have an Account? <Link to="/login">Log In</Link>
                                </p>
                            </div>
                        </div>
                        <div className="col s12 m12 l12 ">
                            <div className="logo  top__bar__content__v2__logo">
                                <a href="#">
                                    <img className="sign_up__logo" src={props.pageLogo} alt="logo" loading="lazy" />
                                </a>
                            </div>
                        </div>
                        <div className="col s6 m6 l6 xl6">
                            <div className="back_btn back__button__update__lighter">
                                <button type="button" id="prevBtn" onClick={() => nextPrev(-1)}>
                                    back
                                </button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <div className="custom__container" style={{ background: props.agencyThemeColor, color: props.agencyTextColor }}>
                <div className="row the-singnup">
                    <div className="singup_left" >
                        <form id="regForm" action="" className="from_content">
                            {/* Step 1 */}
                            <div className="tab sign__up_form">
                                <div style={{ textAlign: "center" }}>
                                    <h5 style={{ color: props.agencyTextColor }} >Let’s Get Started</h5>
                                </div>

                                <div className="row">
                                    <div className="col s12">
                                        {requiredFieldsAlert && <span className={"text-danger"}>All fields are required.</span>}
                                    </div>
                                </div>


                                <div className="row login_form update_login_form_v2">
                                    <div className="input-field login_form_input col s12">
                                        <input value={fullName} placeholder="Enter full name" onChange={(e) => setFullName(e.target.value)} id="name" type="text" className="validate" />
                                        <label style={{ color: props.agencyTextColor }} htmlFor="name" className="active">Your Full Name*</label>
                                    </div>
                                    <div className="input-field login_form_input col s12">
                                        <input value={email} placeholder="Enter email address" onChange={(e) => setEmail(e.target.value)} id="email" type="email" className="validate" />
                                        <label style={{ color: props.agencyTextColor }} htmlFor="email" className="active">Email Address*</label>
                                    </div>
                                    <div className="input-field login_form_input col s12">
                                        <input value={phoneNumber} placeholder="Enter phone number" onChange={(e) => checkLetterExistInText(e.target.value)} id="phn_no" type="text" className="validate" />
                                        <label style={{ color: props.agencyTextColor }} htmlFor="phn_no" className="active">Phone Number* <small className={"sign-up-small"}>(Number must be able to receive a text)</small></label>
                                    </div>

                                </div>
                                <div className="copyright_text">
                                    <p>{props.agencyName} {(new Date()).getFullYear()} - All Rights Reserved </p>
                                    <div className="privecy_text">
                                        {
                                            props.privacyPolicy !== null && props.privacyPolicy !== '' ?
                                                <a href={props.privacyPolicy}>Privacy Policy</a> :
                                                <Link to="/privacy-policy">Privacy Policy</Link>
                                        }

                                        {
                                            props.termsAndConditions !== null && props.termsAndConditions !== '' ?
                                                <a className="ml-5" href={props.termsAndConditions}>Terms And Conditions</a> :
                                                <Link to="/terms-and-conditions" className="ml-5">Terms & Conditions</Link>
                                        }
                                    </div>
                                </div>
                            </div>



                            {/*Step 2*/}
                            <div className="tab sign__up_form"  style={{ background: props.agencyThemeColor, color: props.agencyTextColor }}>
                                <p>Nice to meet you, {fullName}</p>
                                <h5 style={{ color: props.agencyTextColor }}>What is your company's name?</h5>
                                {requiredFieldsAlert && <span className={"text-danger"}>Company field is required.</span>}
                                <div className="input-field login_form_input input_company_name">
                                    <input value={companyName} placeholder="Enter company name*" onChange={(e) => setCompanyName(e.target.value)} id="c_name" type="text" className="validate" />
                                </div>
                            </div>

                            {/* Step 3 */}
                            <div className="tab"  style={{ background: props.agencyThemeColor, color: props.agencyTextColor }}>
                                <h5 style={{ color: props.agencyTextColor }} >What industry are you in?*</h5>
                                {requiredFieldsAlert && <span className={"text-danger"}>Industry field is required.</span>}
                                <div className="input-field company_select mb-2 sign__up_company_type">
                                    <Select
                                        value={companyCategory}
                                        onChange={(_selectedOption) => { setCompanyCategory(_selectedOption.target.value) }}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "center",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "center",
                                            },
                                            getContentAnchorEl: null,
                                            className: "sign__up_company_type_dropdown"
                                        }}
                                    >
                                        <MenuItem value="" disabled>Select your industry</MenuItem>
                                        {renderCompanyCategory()}
                                    </Select>

                                </div>
                            </div>

                            {/* Step 4 */}
                            <div className="tab"  style={{ background: props.agencyThemeColor, color: props.agencyTextColor }}>
                                <h5 style={{ color: props.agencyTextColor }}>How Did You Hear About Us?*</h5>
                                {requiredFieldsAlert && <span className={"text-danger"}>About us field is required.</span>}
                                <div className="input-field company_select sign__up_company_type mb-2">
                                    <Select
                                        value={aboutUsItem}
                                        onChange={(_selectedOption) => { setAboutUsItem(_selectedOption.target.value) }}
                                        displayEmpty
                                        inputProps={{ 'aria-label': 'Without label' }}
                                        MenuProps={{
                                            anchorOrigin: {
                                                vertical: "bottom",
                                                horizontal: "center",
                                            },
                                            transformOrigin: {
                                                vertical: "top",
                                                horizontal: "center",
                                            },
                                            getContentAnchorEl: null,
                                            className: "sign__up_company_type_dropdown"
                                        }}
                                    >
                                        <MenuItem value="" disabled>Choose an option</MenuItem>
                                        {renderAboutItems()}
                                    </Select>
                                </div>
                            </div>

                            <div className="row"  style={{ background: props.agencyThemeColor, color: props.agencyTextColor }}>
                                <div className={`next_btn ${(currentTab === 0) ? 'col s12' : ''}`}>
                                    <button type="button" className="next-button-signup" id="nextBtn" style={{ background: props.accentColor, color: props.color }} onClick={() => nextPrev(1)}>
                                        {buttonText}
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {
                props.googleCaptchaSiteKey && (
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        size="invisible"
                        sitekey={props.googleCaptchaSiteKey.value}
                    />
                )
            }
        </React.Fragment>
    );
};


const mapStateToProps = state => {
    return {
        agencyId: state.signupReducer.agencyId,
        aboutUsItems: state.signupReducer.aboutUsItems,
        companyCategories: state.signupReducer.companyCategories,
        packages: state.signupReducer.packages,
        googleCaptchaSiteKey: state.signupReducer.googleCaptchaSiteKey,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getSignUpPageData: (params) => dispatch(getSignUpPageData(params)),
        updateValidationCheck: (params) => dispatch(updateValidationCheck(params)),
        updateLoading: (params) => dispatch(updateLoading(params)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUpForm);