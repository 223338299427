import React, { useEffect, useState } from "react";
import './signup.css';
import { connect } from "react-redux";
import { getSignUpPageData } from "../../actions/signupAction";
import Loading from "../common/Loading";
import SignUpForm from "./SignUpForm";
import SelectPackage from "./SelectPackage";
import SignUpSuccess from "./SignUpSuccess";
import {Helmet} from "react-helmet";
import Utils from "../../helpers/Utils";
import {CODE_SNIPPET} from "../../constants/CoreConstants";

const RegisterV1 = props => {
    const [loading, setLoading] = useState(props.loading);
    const [validationCheck, setValidationCheck] = useState(props.validationCheck);
    const [isSignUpSuccess, setIsSignUpSuccess] = useState(props.isSignUpSuccess);
    const [pageInfo, setPageInfo] = useState({
        logo: '',
        favIcon: '',
        agencyName: '',
        privacyPolicy: '',
        termsAndConditions: '',
        agencyId: null
    });

    useEffect(() => {
        props.getSignUpPageData({'type': props.match.params.type}, responseSignUpPageData);
    }, []);

    useEffect(() => {
        setValidationCheck(props.validationCheck);
    }, [props.validationCheck]);

    useEffect(() => {
        setLoading(props.loading);
    }, [props.loading]);

    useEffect(() => {
        setIsSignUpSuccess(props.isSignUpSuccess);
    }, [props.isSignUpSuccess]);

    const responseSignUpPageData = response => {
        setPageInfo({
            ...pageInfo,
            logo: response.data.agencyLogo,
            favIcon: response.data.favIcon,
            agencyName: response.data.agencyName,
            agencyId: response.data.agencyId,
            agencyAccentColor: response.data.agencyAccentColor,
            privacyPolicy: response.data.privacyPolicy,
            termsAndConditions: response.data.termsAndConditions,
        });

        Utils.loadCodeSnippets(CODE_SNIPPET.REGISTRATION, {agencyId: response.data.agencyId});
    }

    return (
        <div className="signup__wrapper">
            <Helmet>
                <link rel="icon" href={pageInfo.favIcon} />
                <title>{`Signup | ${pageInfo.agencyName}`}</title>
                <meta name="description" content="All-In-One Sales & Marketing Platform" />
                <meta name="keywords" content={`CRM, Automation, Sales, Marketing, ${pageInfo.agencyName}`} />
                <meta name="author" content={pageInfo.agencyName} />
            </Helmet>
            {loading &&
                <div className='pre-loader'>
                    <Loading />
                </div>
            }
            {!loading &&
                !isSignUpSuccess ?
                    <React.Fragment>
                        {!validationCheck &&
                            <SignUpForm
                                signupType={props.match.params.type}
                                pageLogo={pageInfo.logo}
                                accentColor={pageInfo.agencyAccentColor}
                                agencyName={pageInfo.agencyName}
                                privacyPolicy={pageInfo.privacyPolicy}
                                termsAndConditions={pageInfo.termsAndConditions}
                            />
                        }
                        {validationCheck &&
                            <SelectPackage
                                privacyPolicy={pageInfo.privacyPolicy}
                                termsAndConditions={pageInfo.termsAndConditions}
                                agencyId={pageInfo?.agencyId}
                            />
                        }
                    </React.Fragment> :
                    <SignUpSuccess logo={pageInfo.logo}/>
            }
        </div>
    );
};


const mapStateToProps = state => {
    return {
        validationCheck: state.signupReducer.validationCheck,
        loading: state.signupReducer.loading,
        agencyId: state.signupReducer.agencyId,
        isSignUpSuccess: state.signupReducer.isSignUpSuccess,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        getSignUpPageData: (params, callback) => dispatch(getSignUpPageData(params, callback)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterV1);