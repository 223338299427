import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import './login.css'
import { attemptLogin, loginSuccess } from "../../actions/loginAction";
import { deleteCookie, getCookie, setCookie, setCookieLogin } from "../../helpers/Cookie";
import Utils from "../../helpers/Utils";
import { fetchLoginPageData } from "../../api/loginApi";
import { Helmet } from "react-helmet";
import ListSkeleton from "../common/Skeletons/ListSkeleton";
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { CUSTOM_DOMAIN_ACTIVE } from "../../helpers/CoreConstant";
import OtpFormForLogin from "./OtpFormForLogin";

const Login = props => {

    const history = useHistory();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [agencyId, setAgencyId] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState({});
    const [userApiKeyForOtp, setUserApiKeyForOtp] = useState('');
    const [pageInfo, setPageInfo] = useState({
        logo: '',
        favIcon: '',
        agencyName: '',
        agencyAccentColor: '',
        agencyThemeColor: '',
        agencyTextColor: '',
        privacyPolicy: '',
        termsAndConditions: ''
    });
    const [textTypeChange, setTextTypeChange] = useState(false);

    useEffect(() => {
        //Check access token
        const accessToken = getCookie(process.env.REACT_APP_ACCESS_TOKEN);
        if (accessToken.length !== 0) {
            let redirect_url = checkAddressUrl()
            if (redirect_url !== undefined && redirect_url !== null) {
                history.push(redirect_url);
            } else {
                history.push('/');
            }
        }
        //Fetch agency id from url
        fetchLoginPageData().then(response => {
            setLoading(false);

            if (response.data.status) {

                if (response.data.data.customDomainActive === CUSTOM_DOMAIN_ACTIVE && response.data.data.domainPrefix === window.location.host && response.data.data.domainPrefix !== response.data.data.customDomain) {
                    window.location.replace(window.location.href.replace(response.data.data.domainPrefix, response.data.data.customDomain));
                } else {
                    setAgencyId(response.data.data.agencyId);
                    setPageInfo({
                        ...pageInfo,
                        logo: response.data.data.agencyLogo,
                        favIcon: response.data.data.favIcon,
                        agencyName: response.data.data.agencyName,
                        agencyAccentColor: response.data.data.agencyAccentColor,
                        agencyThemeColor: response.data.data.agencyThemeColor,
                        agencyTextColor: response.data.data.agencyTextColor,
                        privacyPolicy: response.data.data.privacyPolicy,
                        termsAndConditions: response.data.data.termsAndConditions,
                    });
                }
            } else {
                window.showNotification("ERROR", "Whoops! something went wrong. Please reload the page")
            }
        }).catch((error) => {
            if (window.showNotification) {
                window.showNotification("ERROR", "Whoops! something went wrong.")
            }
        });
        
    }, [props.apiKey]);

    const handleSubmit = e => {
        e.preventDefault();

        let formData = new FormData();
        formData.append('email', email);
        formData.append('password', password);
        formData.append('agencyId', process.env.REACT_APP_PRODUCTION_MODE === 'true' ? agencyId : 236);

        props.attemptLogin(formData, loginResponse);
    }

    const loginResponse = response => {
        let mailTo = '';
        if (response.agencyEmail !== undefined) {
            mailTo = "mailto:" + response.agencyEmail;
        }

        if (response.status === 'success') {
            deleteCookie(process.env.REACT_APP_ACCESS_PARENT_TOKEN);
            setCookie(process.env.REACT_APP_ACCESS_TOKEN, response.api_key, process.env.REACT_APP_ACCESS_TOKEN_VALIDITY);
            setCookieLogin(process.env.REACT_APP_ACCOUNT_DATA, JSON.stringify(response.accountData), process.env.REACT_APP_ACCESS_TOKEN_VALIDITY);
            props.loginSuccess(response.api_key);
        } else if (response.status === 'invalid_credentials') {
            setLoading(false);
            setError({ message: <>Your email or password is incorrect. If you don't remember your password, <a href={"/password-reset"}>reset it now.</a></> })
        } else if (response.status === 'invalid_email') {
            setLoading(false);
            setError({ message: <>The email you entered is incorrect.</> })
        } else if (response.status === 'invalid_password') {
            setLoading(false);
            setError({ message: <>The password you entered is incorrect. If you don't remember your password, <a href={"/password-reset"}>reset it now.</a></> })
        } else if (response.status === 'validation-error') {
            setLoading(false);
            setError({ message: response.message });
        } else if (response.status === 'status_pending') {
            setLoading(false);
            setError({ message: <>Your account is currently in pending mode. Please contact our team at <a href={mailTo}>{response.agencyEmail}</a> to activate your account.</> });
        } else if (response.status === 'status_inactive') {
            setLoading(false);
            setError({ message: <>Your account is suspended, to know more please contact with our <a href='https://help.salespype.com/'>support</a></> });
        } else if (response.status === 'otp_needed') {
            setLoading(false);
            setUserApiKeyForOtp(response.api_key);
        } else {
            setLoading(false);
            Utils.showNotification('Whoops! Something went wrong. Please try again.');
        }
    }

    const checkAddressUrl = () => {
        const queryParams = new URLSearchParams(window.location.search);
        return queryParams.get('redirect_url');
    }

    return (
        <div className="h__100__percent" style={{ background: pageInfo.agencyThemeColor, color: pageInfo.agencyTextColor }}>
            <Helmet>
                <link rel="icon" href={pageInfo.favIcon} />
                <title>{`Login | ${pageInfo.agencyName}`}</title>
                <meta name="description" content="All-In-One Sales & Marketing Platform" />
                <meta name="keywords" content={`CRM, Automation, Sales, Marketing, ${pageInfo.agencyName}`} />
                <meta name="author" content={pageInfo.agencyName} />
            </Helmet>
            {loading &&
                <div className="container h__100__percent">
                    <div className="main_logo" style={{ margin: '0 auto', width: '250px' }}>
                        <ListSkeleton width={250} height={100} />
                    </div>
                    <div className="main_logo" style={{ margin: '0 auto', width: '300px' }}>
                        <ListSkeleton width={300} />
                    </div>
                    <br />
                    <div className="main_logo" style={{ margin: '0 auto', width: '500px' }}>
                        <ListSkeleton width={500} number={5} />
                    </div>
                    <br />
                    <div className="main_logo" style={{ margin: '0 auto', width: '500px' }}>
                        <ListSkeleton width={500} />
                    </div>
                    <div className="main_logo" style={{ margin: '0 auto', width: '250px' }}>
                        <ListSkeleton width={250} />
                    </div>
                </div>
            }
            {!loading &&
                userApiKeyForOtp === '' ?
                    <div className="container h__100__percent">
                        <div className="row h__100__percent">
                            <div className="center-align h__100__percent singin_content">
                                <div className="singin_form">
                                    <div className="main_logo">
                                        <img className="login-page-logo" src={pageInfo.logo} alt="Logo" loading="lazy" />
                                    </div>
                                    <div className="account_text" style={{ color: pageInfo.agencyTextColor }}>
                                        <p>Don’t Have an Account? <Link to="/paid/get-started">Sign Up</Link></p>
                                    </div>
                                    {
                                        typeof error.message != 'undefined' &&
                                        <div className="error_wrapper">
                                            <p className="text-danger">{error.message}</p>
                                        </div>
                                    }

                                    <form className="row login_form" onSubmit={(e) => handleSubmit(e)}>
                                        <div className="input-field login_form_input col s12">
                                            <input placeholder="Enter Your Email Address" id="email" type="email" required={'required'}
                                                onChange={(e) => {
                                                    setError({});
                                                    setEmail(e.target.value);
                                                }} value={email}
                                                className="validate" />
                                            <label htmlFor="email" className="active" style={{ color: pageInfo.agencyTextColor }}>Email Address</label>
                                            {
                                                typeof error.email !== 'undefined' &&
                                                <span className="text-danger">{error.email}</span>
                                            }
                                        </div>
                                        <div className="input-field login_form_input col s12 mb-1 ">
                                            <input required={'required'} placeholder="Enter password" id="password" type={textTypeChange ? 'text' : 'password'}
                                                onChange={(e) => setPassword(e.target.value)} value={password}
                                                className="validate" />

                                            {
                                                textTypeChange && <span className="visible__span" onClick={() => setTextTypeChange(!textTypeChange)}><VisibilityIcon /></span>
                                            }
                                            {
                                                !textTypeChange && <span className="visible__span" onClick={() => setTextTypeChange(!textTypeChange)}><VisibilityOff /></span>
                                            }

                                            <label style={{ color: pageInfo.agencyTextColor }} htmlFor="password" className="active">Password</label>
                                            {
                                                typeof error.password !== 'undefined' &&
                                                <span className="text-danger">{error.password}</span>
                                            }
                                        </div>
                                        <div action="#" className="check_box remember-pass">
                                            <label style={{ color: pageInfo.agencyTextColor }}>
                                                <input type="checkbox" className="filled-in" />
                                                <span style={{ color: pageInfo.agencyTextColor }}>Remember me</span>
                                            </label>
                                            <Link style={{ color: pageInfo.agencyTextColor }} to="/password-reset">Forget Password?</Link>
                                        </div>
                                        <button type='submit' className="login_btn" style={{ background: pageInfo.agencyAccentColor, cursor: 'pointer' }}>Log In</button>
                                    </form>
                                </div>
                                <div className="licence_text">
                                    <h6>{pageInfo.agencyName} {(new Date()).getFullYear()} - All Rights Reserved</h6>
                                    {
                                        (pageInfo.privacyPolicy !== null && pageInfo.privacyPolicy !== '') ?
                                            <a className="pr-4" href={pageInfo.privacyPolicy}>Privacy Policy</a> :
                                            <Link className="pr-4 okk" to="/privacy-policy">Privacy Policy</Link>
                                    }

                                    {
                                        (pageInfo.termsAndConditions !== null && pageInfo.termsAndConditions !== '') ?
                                            <a href={pageInfo.termsAndConditions}>Terms And Conditions</a> :
                                            <Link to="/terms-and-conditions">Terms And Conditions</Link>
                                    }
                                </div>
                            </div>
                        </div>
                    </div> :
                    <OtpFormForLogin
                        pageLogo={pageInfo.logo}
                        accentColor={pageInfo.agencyAccentColor}
                        agencyThemeColor={pageInfo.agencyThemeColor}
                        agencyTextColor={pageInfo.agencyTextColor}
                        agencyName={pageInfo.agencyName}
                        email={email}
                        password={password}
                        agencyId={agencyId}
                    />
            }
        </div>
    )
}

const mapStateToProps = state => {
    return {
        user: state.loginReducer.user,
        apiKey: state.loginReducer.apiKey,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        attemptLogin: (params, callback) => dispatch(attemptLogin(params, callback)),
        loginSuccess: (params) => dispatch(loginSuccess(params)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);