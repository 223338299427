import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import moment from "moment";
import PaymentForm from './PaymentForm';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { Link } from "react-router-dom";

const App = (props) => {
    const stripePromise = loadStripe(props.stripePublicKey);

    const [selectedPackage, setSelectedPackage] = useState(props.selectedPackage);

    useEffect(() => {
        setSelectedPackage(props.selectedPackage);
    }, [props.selectedPackage]);

    return (
        <div className="center-align">
            <div className="price_package_content">
                <div className="row">
                    <div className="col xl7 payment_content">
                        <div className="top_title">
                            <h1>Billing</h1>
                            <p>Complete the form below to create your new account!</p>
                        </div>
                        <div className="payment_card_form">
                            <div className="row">
                                <Elements stripe={stripePromise}>
                                    <PaymentForm />
                                </Elements>
                            </div>
                        </div>
                        <div className="secqure_payment left-align">
                            <div className="bank_logo">
                                <div className="row">
                                    <div className="col s12">
                                        <ul className="d-flex justify-content-center">
                                            <li><img src={`${process.env.REACT_APP_CDN_LINK}asset/images/releases/1.0.0/Visa.png`} alt="" /></li>
                                            <li><img src={`${process.env.REACT_APP_CDN_LINK}asset/images/releases/1.0.0/Master.png`} alt="" /></li>
                                            <li><img src={`${process.env.REACT_APP_CDN_LINK}asset/images/releases/1.0.0/Maestro.png`} alt="" /></li>
                                            <li><img src={`${process.env.REACT_APP_CDN_LINK}asset/images/releases/1.0.0/JCB.png`} alt="" /></li>
                                            <li><img src={`${process.env.REACT_APP_CDN_LINK}asset/images/releases/1.0.0/Discover.png`} alt="" /></li>
                                        </ul>
                                        <p>100% Safe & Secure</p>
                                    </div>
                                </div>
                            </div>
                            <p>No contracts or commitments. Cancel anytime.</p>
                        </div>
                    </div>
                    <div className="col xl5 price_checkout">
                        <div className="packages">

                            {
                                selectedPackage.trial_pack == 1 &&
                                <>
                                    <ul>
                                        <li className="package_list top_title"><h1 className="text--blue">Package : {selectedPackage.name}</h1></li>
                                    </ul>
                                    <div>
                                        <ul>
                                            <li className="package_list">Today's Payment:</li>
                                            <li className="price">$0</li>
                                        </ul>

                                        <ul>
                                            <li className="package_list">Future Payment's:</li>
                                            <li className="price">${selectedPackage.price}/mo</li>
                                        </ul>
                                    </div>
                                </>
                            }

                            {
                                selectedPackage.trial_pack == 0 &&
                                <>
                                    <ul>
                                        <li className="package_list top_title"><h1 className="text--blue">Package : {selectedPackage.name}</h1></li>
                                    </ul>
                                    <div className={"bill-info-outer-box"}>
                                        <ul>
                                            <li className="package_list">Today's Payment:</li>
                                            <li className="price">${selectedPackage.price}</li>
                                        </ul>
                                        <ul>
                                            <li className="package_list">Future Payment's:</li>
                                            <li className="price">${selectedPackage.price}/mo</li>
                                        </ul>
                                    </div>
                                </>
                            }

                            {
                                selectedPackage.trial_pack == 0 &&
                                <div className="price_list">
                                    <h2>You'll be Charged</h2>
                                    <p>${selectedPackage.price} <span>Today</span></p>
                                </div>
                            }


                            <div className="warrning">
                                <p>Your monthly plan will be ${selectedPackage.price}. It will automatically renew until your cancel. The next billing will occur on <span>{moment().add(selectedPackage.life_line, 'd').format('MMMM D YYYY, h:mm A')}</span></p>
                            </div>
                            <div className="privecy_policy">
                                <p>
                                    {
                                        props.privacyPolicy !== null && props.privacyPolicy !== '' ?
                                            <a href={props.privacyPolicy}>Terms of Use & Privacy Policy</a> :
                                            <Link to="/privacy-policy">Terms of Use & Privacy Policy</Link>
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    return {
        selectedPackage: state.signupReducer.selectedPackage,
    };
};


const Payment = connect(mapStateToProps, null)(App);

export default Payment;