import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Typography,
  makeStyles,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import React, { useState } from "react";
import { connect } from "react-redux";
import {
  getContactTagList,
  getUserTagList,
} from "../../../../actions/contactAction";
import DynamicSkeleton from "../../../common/DynamicSkeleton";
import Tags from "../../moreInfo/tags/Tags";
import CustomTitle from "../common/CustomTitle";
import TagsList from "./TagsList";

const useStyles = makeStyles({
  hoverDiv: {
    "&:hover": {
      "& $icon": {
        opacity: 1,
        transition: "width .35s",
        width: "42px",
      },
    },
  },
  icon: {
    opacity: 0,
    width: 0,
  },
});

const TagsForContact = (props) => {
  const [openModal, setOpenModal] = useState(false);

  const handleSubmit = () => {
    getTagList();
    handleOpen();
  }

  const handleOpen = () => {
    setOpenModal((prevState) => !prevState);
  };

  const getTagList = () => {
    props.getContactTagList({
      contactId: props.contact.id,
    });
  };
  const icons = useStyles();
  return (
    <Box className={icons.hoverDiv} sx={{ mt: 3 }}>
      <Box sx={{ gap: 10 }} mb={2}>
        <CustomTitle
          handleOpen={handleOpen}
          title={"TAGS"}
          iconHide={icons.icon}
        />
      </Box>
      {props.tagLoading ? <DynamicSkeleton count={1} /> : <TagsList />}

      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={"right"}
            open={openModal}
            onClose={() => setOpenModal(false)}
          >
            <Box sx={{ width: "500px", padding: "16px" }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="h5">Assign Tags</Typography>
                <IconButton onClick={() => setOpenModal(false)}>
                  <Close />
                </IconButton>
              </Box>
              <Divider />
              <Box marginTop={"16px"}>
                <Tags
                  handleTagSubmit={handleSubmit}
                  contactId={props?.contact?.id}
                  setOpenModal={setOpenModal}
                />
              </Box>
            </Box>
          </Drawer>
        </React.Fragment>
      ))}
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    tagLoading: state.tagReducer.tagLoading,
    contact: state.addContactReducer.contact,
    contactTags: state.tagReducer.tags,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getContactTagList: (params) => dispatch(getContactTagList(params)),
    fetchUserTagList: (params, callback) =>
      dispatch(getUserTagList(params, callback)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(TagsForContact);
