import React, { useRef, useState } from 'react';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { handleStateChange, verifyUserOTP } from "../../actions/signupAction";
import { resendOtpFromRegistrationPageScreen } from "../../api/signupApi";
import './OtpForm.css';
import './signup.css';

const OtpForm = props => {
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [otp, setOtp] = useState(['', '', '', '', '']);
    const inputRefs = useRef([]);

    const handleChange = (event, index) => {
        setShowErrorMessage(false);
        const newOtp = [...otp];
        newOtp[index] = event.target.value;
        setOtp(newOtp);
    };

    const focusNext = (event, index) => {
        if ((event.key === 'Delete' || event.key === 'Backspace')) {
            event.preventDefault();
        } else {
            if (index === 4) return;
            inputRefs.current[index + 1].focus();
        }
    };

    const verifyOtp = (e) => {
        e.preventDefault();

        let userOtp = otp.join("");
        if (userOtp.length !== 5) {
            setShowErrorMessage(true);
            return;
        }
        props.handleStateChange({ otpError: false })
        setSubmitting(true);
        let formData = new FormData();
        formData.append('userOtp', userOtp);
        formData.append('api_key', props.userApiKey);
        props.verifyUserOTP(formData, () => {
            setSubmitting(false);
        });
    }

    const resendOtp = () => {
        let formData = new FormData();

        formData.append('api_key', props.userApiKey);

        resendOtpFromRegistrationPageScreen(formData)
            .then(response => {
                if (response.data.status === 'success') {
                    window.showNotification("SUCCESS", response.data.message);
                } else {
                    window.showNotification("ERROR", response.data.message)
                }
            }).finally(() => {

            });
    }

    return (
        <React.Fragment>
            <div className="topbar">
                <div className="row">
                    <div className="topbar_content top__bar__content__v2 ">
                        <div className="col s12 m12 l12 lognin_link">
                            <div className="text text_v2">
                                <p>
                                    Already Have an Account? <Link to="/login">Log In</Link>
                                </p>
                            </div>
                        </div>
                        <div className="col s12 m12 l12 ">
                            <div className="logo  top__bar__content__v2__logo">
                                <a href="#">
                                    <img className="sign_up__logo" src={props.pageLogo} alt="logo" loading="lazy" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="custom__container">
                <div className="row">
                    <div className="otp_form_wrapper" >
                        <form id="" action="" className="from_content otp_form_login_page">
                            <h5 className="form_content_title">Please enter OTP</h5>
                            <p>We have sent you one time password to your mobile</p>
                            {/*<span className="form_content_time">1:52</span>*/}

                            <div className="verification_code">
                                {otp.map((field, index) => (
                                    <input
                                        key={index}
                                        type="text"
                                        value={field}
                                        onChange={(e) => handleChange(e, index)}
                                        onKeyUp={(e) => focusNext(e, index)}
                                        ref={(el) => (inputRefs.current[index] = el)}
                                        maxLength={1}
                                    />
                                ))}
                            </div>

                            {
                                showErrorMessage &&
                                <p className="form_content_otp_error">
                                    OTP length must be 5 digits long
                                </p>
                            }

                            {
                                props.otpError &&
                                <p className="form_content_otp_error">
                                    OTP is not correct, please try again
                                </p>
                            }

                            <p className="form_content_resend">
                                Didn't receive the OTP?
                                <a href="#" className="resend" onClick={() => resendOtp()}> RESEND</a>
                            </p>

                            {
                                !submitting ?
                                    <button className="btn_verify" onClick={(e) => verifyOtp(e)}>Verify</button> :
                                    <button className="btn_verify" disabled>Verifying . . . </button>
                            }
                        </form>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};


const mapStateToProps = state => {
    return {
        otpError: state.signupReducer.otpError,
        userApiKey: state.signupReducer.otpNeededForUser,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        verifyUserOTP: (params, callback) => dispatch(verifyUserOTP(params, callback)),
        handleStateChange: (params) => dispatch(handleStateChange(params)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OtpForm);