import * as ACTION from "../constants/contactActionTypes";

const initialState = {
    tags : null,
    tagLoading: true,
};

const tagReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION.GET_CONTACT_TAG:
            return {...state, tags: null, tagLoading: true};
        case ACTION.SET_CONTACT_TAG:
            return {...state,tags:action.payload, tagLoading: false};
        default:
            return state;
    }
}

export default tagReducer;
