import { Chip, makeStyles } from "@material-ui/core";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { AGENCY_BUSINESS_TYPE } from "../../../../helpers/CoreConstant";
import Utils from "../../../../helpers/Utils";

const useStyles = makeStyles(() => ({
  tagWrapper: {
    backgroundColor: "#f3f8ff",
    marginBottom: "10px",
    borderRadius: "5px",
    padding: "5px",
    paddingTop: "12px",
    paddingBottom: "12px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
  },
  eachTag: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  textIconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "12px",
  },
  chipStyle: {
    width: "fit-content",
    height: "26px",
    fontWeight: 600,
    color: "#FFFFFF",
    backgroundColor: (props) => props.color || "#346fef",
    "& .MuiChip-label": {
      color: "#ffffff",
    },
  },
}));
export const EachTag = (props) => {
  const classes = useStyles({ color: props.item.color });
  const agencyBusinessType = Utils.getAccountData("agencyBusinessType");
  const isAgencyTypeInsurance =
  agencyBusinessType && agencyBusinessType === AGENCY_BUSINESS_TYPE.INSURANCE;
  let isChecked = props.selectedTags.includes(props.item.id);
  return (
    <div className={`g-each-tag-content ${classes.tagWrapper}`}>
      <div
        className={`g-selected-tag-each ${classes.eachTag}`}
        key={props.key}
        onClick={() => props.onCheckChange(props.item.id, props.item.name)}
      >
        <div className={classes.textIconWrapper}>
          {isChecked ? (
            <CheckBoxIcon style={{ color: "#666dfb" }} />
          ) : (
            <CheckBoxOutlineBlankIcon style={{ color: "#666dfb" }} />
          )}
          <span>{props.item.name}</span>
        </div>
        {isAgencyTypeInsurance && props.item.privacy_level && (
          <Chip
            label={props.item.privacy_level}
            className={classes.chipStyle}
          />
        )}
      </div>
    </div>
  );
};
