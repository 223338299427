export const CUSTOM_DOMAIN_ACTIVE = 1;

// List of suspicious keywords or phrases commonly found in scam messages
export const SCAM_KEYWORDS = [
    // Urgency and Fear Tactics
    "urgent", "immediate action required", "act now", "limited time offer", "time-sensitive", "respond immediately", "today only",
    "don't delay", "final notice", "last chance", "warning", "penalty", "act immediately", "don't miss out", "opportunity", "threat detected", "you're at risk",

    // Financial Scams and Fraud
    "lottery", "prize", "winner", "cash prize", "inheritance", "winning", "transfer", "payout", "money", "wire transfer", "debit", "charged", "payment", "transaction",
    "advance fee", "investment", "credit", "deposit", "refund", "overdue payment", "billing issue", "compensation", "grant", "loan approval", "debt relief",
    "bank transfer", "Western Union", "money order", "cashier's check", "Bitcoin", "cryptocurrency", "payment method", "payment details", "PayPal",
    "credit score", "credit report", "interest rate", "mortgage", "settlement", "tax refund", "tax lien", "inheritance claim", "fund release",

    // Phishing and Identity Theft
    "account", "account verification", "unauthorized access", "security alert", "password reset", "confirm account", "verify now",
    "update information", "suspicious activity", "account suspended", "account locked", "unblock", "reactivate account", "login required",
    "social security number", "credit card number", "PIN", "confidential", "sensitive information", "identity verification", "personal details",
    "security question", "KYC", "OTP", "two-factor authentication", "identity proof", "account recovery", "bank statement",

    // Malware and Cybersecurity Threats
    "click here", "visit this link", "fake", "forged", "fraudulent", "phishing", "scam", "malware", "trojan", "virus", "spyware",
    "ransomware", "hack", "data breach", "unauthorized transaction", "compromised account", "network security", "threat detected", "vulnerable",
    "email verification", "download required", "unsecured", "unsafe", "phishing attempt", "infected", "security flaw", "exploit detected",

    // Too Good to Be True Offers
    "free", "amazing deal", "100% guaranteed", "risk-free", "unbelievable", "too good to be true", "act fast", "congratulations", "you're the lucky winner", "exclusive offer", "just pay shipping",
    "limited edition", "one-time offer", "no obligation", "no risk", "special discount", "best price", "hurry up", "free trial", "gift card", "voucher",

    // Fake Support and Impersonation
    "government", "IRS", "customs", "your bank", "your insurance", "tech support", "Microsoft", "Apple Support", "Amazon", "PayPal",
    "customer support", "service representative", "agent", "official", "verified", "bank manager", "financial advisor", "executive",
    "law enforcement", "police", "court order", "legal notice", "official document", "urgent request", "attorney", "solicitor",

    // Contact and Social Engineering Ploys
    "call now", "contact us", "text back", "reply to this message", "get in touch", "speak with an agent",
    "customer service", "hotline", "emergency contact", "helpline", "support desk", "care center", "urgent inquiry", "service center",
    "follow-up", "callback", "response required", "live chat", "immediate support", "direct contact", "feedback required",

    // Fake Job Offers and Employment Scams
    "work from home", "job offer", "hiring", "vacancy", "earn money", "quick cash", "income opportunity", "data entry job",
    "freelance opportunity", "remote work", "high salary", "unlimited earning", "part-time job", "immediate hire", "training fee",

    // Fake Charity and Donation Scams
    "donate", "charity", "help children", "disaster relief", "fundraiser", "nonprofit", "foundation", "support cause",
    "donation request", "help needed", "humanitarian aid", "relief effort", "emergency fund", "crisis support", "volunteer",

    // Miscellaneous Fraudulent Phrases
    "fake check", "false claim", "bogus", "counterfeit", "deceptive", "embezzlement", "extortion", "identity fraud",
    "illicit", "imposter", "manipulate", "mislead", "money laundering", "scam alert", "scheme", "swindle", "victim", "fraudulent transaction",
    "restricted", "deactivated", "safeguard", "authentication failure", "breach of contract", "confidential agreement", "unauthorized withdrawal"
];


export const DEAL_STATUS = {
    WON: 2,
    LOST: 3
};

export const AGENCY_BUSINESS_TYPE = {
    INSURANCE: "INSURANCE",
    REGULAR: "REGULAR"
};
export const PRIVACY_LEVEL = {
    PRIVACY_LEVEL_GLOBAL: 'GLOBAL',
    PRIVACY_LEVEL_PRIVATE: 'PRIVATE'
};
