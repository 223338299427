import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import VideoLibraryModal from "./VideoLibraryModal";
import CoreConstants from "./CoreConstants";
import Webcam from "./Webcam.react";
import RecordRTC from "recordrtc";
import './video.css';
import RecordedVideo from "./RecordedVideo";
import LikeVideo from "./LikeVideo";
import axios from "axios";
import Api from "./redux/Api";
import ProgressBar from "./ProgressBar";
import SavedReply from "./SavedReply";
import LinkFile from "./LinkFile";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import { changeLikeStatus, getVideoFolders, changeSaveAsTemplate } from "./redux/videoEmailAction";
import { MenuItem, Select } from "@material-ui/core";
import VideoValidationAlert from "./VideoValidationAlert";

let videoStream;
let recordVideo;
let recordGIf;
let gif;

let enableSubmit = true;

const VideoEmail = props => {
    const FIRST_WORLD = {
        videoStatus: CoreConstants.VIDEO_STATUS_WELCOME,
        loader: false,
        uploadPercent: 0,
        loaderMessage: 'Open Camera',
        videoUrl: '',
        gifUrl: '',
        isVideoSelected: false,
        videoObj: null,
        activeVideoId: props.emailVideoId,
        fileUpload: false,
        videoPreviewLoader: false,
        willSaveAsTemplate: false,
        likeStatus: false,
        title: (props.videoObj && props.videoObj.title) ? props.videoObj.title : '',
        description: (props.videoObj && props.videoObj.description) ? props.videoObj.description : '',
        folderId: (props.videoObj && props.videoObj.campaign_video_folder_id) ? props.videoObj.campaign_video_folder_id : 'default',
    }

    const [state, setState] = useState(FIRST_WORLD);
    const [videoLibraryModal, setVideoLibraryModal] = useState(false);
    const [savedReplyModal, setSavedReplyModal] = useState(false);
    const [folders, setFolders] = useState(props.videoFolders);
    const [errorData, setErrorData] = useState({
        alertTitle: "",
        alertSubTitle: "",
        alertMessage: "",
        alertSubMessage: "",
        helperMessage: "",
        helperSubMessage: ""
    });
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [totalRecordedSeconds, setTotalRecordedSeconds] = useState(0);
    // const totalVideoSecondsLimit = 120;

    useEffect(() => {
        if (props.videoUrl !== undefined && props.videoUrl != null && props.videoUrl !== "") {
            setTimeout(() => {
                setState({ ...state, videoUrl: props.videoUrl, videoStatus: CoreConstants.VIDEO_STATUS_PREVIEW, isVideoSelected: true })
            }, 100);
        }

        return () => {
            setState({ ...state, videoUrl: '', isVideoSelected: false })
        }
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setState({ ...state, likeStatus: props.likeStatus });
    }, [props.likeStatus]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        setState({ ...state, willSaveAsTemplate: props.willSaveAsTemplate });
    }, [props.willSaveAsTemplate]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (props.videoFolders == null) {
            props.getVideoFolders();
        } else {
            setFolders(props.videoFolders)
        }
    }, [props.videoFolders]); // eslint-disable-line react-hooks/exhaustive-deps

    const renderFolderOptions = () => {
        let folderOptions = []
        if (folders !== null && folders !== undefined) {
            folders.forEach(function (data, index) {
                folderOptions.push(
                    <MenuItem key={data.id} value={data.id}>{data.name}</MenuItem>
                )
            });
        }

        return folderOptions;
    }

    const openCamera = (e) => {
        e.preventDefault();
        e.stopPropagation();
        let hasGetUserMedia = '';
        enableSubmit = true;

        try {
            props.changeLikeStatus(false)
            setState({ ...state, isVideoSelected: false, activeVideoId: null, fileUpload: false });
            hasGetUserMedia = !!(navigator.mediaDevices || navigator.mediaDevices.getUserMedia || navigator.webkitGetUserMedia ||
                navigator.mozGetUserMedia || navigator.msGetUserMedia);
        } catch (e) {
            setState({ ...state, videoStatus: CoreConstants.VIDEO_STATUS_WARNING });
            return 0;
        }


        if (!hasGetUserMedia) {
            setState({ ...state, videoStatus: CoreConstants.VIDEO_STATUS_WARNING });
            return 0;
        }

        requestUserMedia();
    }

    const requestUserMedia = () => {
        navigator.mediaDevices.getUserMedia({ audio: true, video: { width: 1280, height: 720 } })
            .then(function (mediaStream) {
                setState({ ...state, videoStatus: CoreConstants.VIDEO_STATUS_WEBCAM, fileUpload: false });
                let video = document.getElementById('video-email-vid');
                video.srcObject = mediaStream;
                videoStream = mediaStream;
                video.onloadedmetadata = function (e) {
                    video.play();
                };
            })
            .catch(function (err) {
                setState({ ...state, videoStatus: CoreConstants.VIDEO_STATUS_WARNING });
                return 0;
            });
    }

    const startRecord = (totalSeconds) => {
        setTotalRecordedSeconds(totalSeconds);
        try {
            recordVideo = RecordRTC(videoStream, { type: 'video' });
            recordVideo.startRecording();


            recordGIf = RecordRTC(videoStream, { type: 'gif' });
            recordGIf.startRecording();
        }
        catch (err) {
            setState({ ...state, videoStatus: CoreConstants.VIDEO_STATUS_WARNING });
            return 0;
        }
    }

    const stopRecord = (totalSeconds) => {
        setTotalRecordedSeconds(totalSeconds);

        // if (totalSeconds === totalVideoSecondsLimit) {
        //     setShowErrorAlert(true);
        //     setErrorData({
        //         alertTitle: "Video Email Error",
        //         alertSubTitle: "Recording is too long.",
        //         alertMessage: "Your recording is too long.",
        //         alertSubMessage: "Video recordings exceed 2 minutes.",
        //         helperMessage: "",
        //         helperSubMessage: ""
        //     });
        // }

        setState({ ...state, loader: true });
        recordVideo.stopRecording(() => {
            let urlCreator = window.URL || window.webkitURL;
            let videoUrl = urlCreator.createObjectURL(recordVideo.blob);
            videoStream.getTracks().forEach(track => track.stop());

            setState({
                ...state,
                videoUrl: videoUrl,
                gifUrl: gif,
                videoStatus: CoreConstants.VIDEO_STATUS_PREVIEW,
                isVideoSelected: false,
                activeVideoId: null,
                loader: false
            });
            return true;
        });
    }

    const stopGifRecord = () => {
        if (state.gifUrl === '') {
            recordGIf.stopRecording(() => {
                let urlCreator = window.URL || window.webkitURL;
                gif = urlCreator.createObjectURL(recordGIf.blob);
                return false;
            });
        }
    }

    const selectVideo = (isSelectable) => {
        if (isSelectable) {
            if (totalRecordedSeconds < 5 && !state.fileUpload) {
                setShowErrorAlert(true);
                setErrorData({
                    alertTitle: "Video Email Error",
                    alertSubTitle: "Recording is too short.",
                    alertMessage: "Your recording is too short.",
                    alertSubMessage: "Video recordings must be a minimum of 5 seconds long.",
                    helperMessage: "",
                    helperSubMessage: ""
                });
                return;
            }

            if (!state.activeVideoId) {
                setState({ ...state, loader: true });
                // create FormData
                let formData = new FormData();
                formData.append('api-key', LinkFile.getCookie(process.env.REACT_APP_ACCESS_TOKEN));

                if (state.fileUpload) {
                    formData.append('video-file', document.getElementById('video-file').files[0]);
                    formData.append('gif-convert-required', 1);
                } else {
                    let blob = recordVideo instanceof Blob ? recordVideo : recordVideo.getBlob();
                    blob = new File([blob], 'RecordRTC-video.webm', {
                        type: 'video/webm'
                    });


                    let gifBlob = recordGIf instanceof Blob ? recordGIf : recordGIf.getBlob();
                    gifBlob = new File([gifBlob], 'RecordRTC-gif.gif', {
                        type: 'image/gif'
                    });

                    formData.append('video-file', blob);
                    formData.append('gif-file', gifBlob);
                }

                if (enableSubmit) {
                    enableSubmit = false;
                    axios.post(Api.uploadVideo, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        onUploadProgress: function (progressEvent) {
                            document.getElementById('progress-bar').style.width = (Math.round((progressEvent.loaded / progressEvent.total) * 100)) + '%';
                            document.getElementById('progress-bar-number').innerText = (Math.round((progressEvent.loaded / progressEvent.total) * 100)) + '%';
                        }.bind(this)
                    }).then((response) => {
                        let data = response.data;
                        if (data.status === 'success') {
                            setState({
                                ...state,
                                loader: false,
                                isVideoSelected: true,
                                videoPreviewLoader: false
                            });
                            if (window.showNotification !== undefined) {
                                window.showNotification("SUCCESS", data.msg)
                            }
                            props.changeLikeStatus(true);
                            props.setEmailVideoId(data.id);
                        } else {
                            setState({
                                ...state,
                                loader: false,
                                isVideoSelected: false,
                                videoPreviewLoader: false
                            });
                            if (window.showNotification !== undefined) {
                                window.showNotification("ERROR", data.msg)
                            }
                        }
                    }).catch((error) => {
                        setState({ ...state, loader: false, videoSelected: false, videoPreviewLoader: false });
                        if (window.showNotification !== undefined) {
                            window.showNotification("ERROR", error.response.data)
                        }
                    });
                }
            }
        } else {
            setState(FIRST_WORLD);
        }
    }

    const loadFile = e => {
        let file = e.target.files[0];
        if (file.type === 'video/mp4') {
            if (file.size < 100 * 1024 * 1024) {
                let videoUrl = window.URL.createObjectURL(file);
                props.changeLikeStatus(false);
                enableSubmit = true;
                setState({
                    ...state,
                    videoUrl: videoUrl,
                    activeVideoId: null,
                    isVideoSelected: false,
                    videoStatus: CoreConstants.VIDEO_STATUS_PREVIEW,
                    previewVideo: true,
                    fileUpload: true,
                    videoPreviewLoader: false
                });
            } else {
                setShowErrorAlert(true);
                setErrorData({
                    alertTitle: "Video Email Error",
                    alertSubTitle: "File size is too large.",
                    alertMessage: "Your video file size is too large.",
                    alertSubMessage: "The video maximum file size allowed is 100mb.",
                    helperMessage: "Please upload a smaller file or consider using a video compression tool to reduce your file size.",
                    helperSubMessage: "(Google video compression for options)"
                });
            }
        } else {
            setShowErrorAlert(true);
            setErrorData({
                alertTitle: "Video Email Error",
                alertSubTitle: "Invalid file format.",
                alertMessage: "Your video file format is invalid.",
                alertSubMessage: "All videos must be mp4 files.",
                helperMessage: "Please upload a new video or convert your current file to an mp4 using a video conversion tool.",
                helperSubMessage: "(Google convert video file for options)"
            });
        }
    }

    const loadSelectedVideoFile = fileInfo => {
        setState({
            ...state,
            activeVideoId: fileInfo.id,
            videoUrl: fileInfo.video_link,
            isVideoSelected: true,
            videoStatus: CoreConstants.VIDEO_STATUS_PREVIEW,
            previewVideo: true,
            fileUpload: false,
            videoPreviewLoader: false
        });
        props.setEmailVideoId(fileInfo.id);
        setVideoLibraryModal(false)
    }

    const useMessageTemplate = data => {
        setSavedReplyModal(false);
        setState({
            ...state,
            videoUrl: data.video_file_url ?? data.template_files.file,
            gifUrl: data.gif_file_url,
            videoStatus: CoreConstants.VIDEO_STATUS_PREVIEW,
            isVideoSelected: true,
            videoPreviewLoader: false,
            willSaveAsTemplate: false,
            loader: false
        });

        props.useMessageTemplate(data);
    }

    return (
        <div className={`video_contents__alt video__mail__back ${props.className}`}>
            <div className="row mt-3">
                {
                    state.loader ?
                        <ProgressBar completed={state.uploadPercent} /> :
                        <>
                            <div className="col s12 m12 l6 xl6">
                                <h6 className="font__size__14 m-0 select-option-label">Select an option below:</h6>
                                <div className="flexItemCenter__alt video-action">
                                    <div className="d-flex justify-content-center align-items-center video-action-item">
                                        <a className="voice_mail_icon__altr" href="#" onClick={(e) => openCamera(e)}>
                                    <span className="d-flex justify-content-center align-items-cent">
                                        <i className="accent--color material-icons">videocam</i>
                                    </span>
                                            <p className="text-gray__altr font__size__14 margin__top__bottom">Record</p>
                                        </a>
                                        <div className="voice_mail_icon__altr ml-5 cursor-pointer">
                                            <label
                                                className="d-flex justify-content-center align-items-center cursor-pointer">
                                                <input type="file" className="d-none" accept=".mp4"
                                                       onChange={(e) => loadFile(e)}
                                                       id="video-file"/>
                                                <i className="accent--color material-icons ">file_upload</i>
                                            </label>
                                            <p className="text-gray__altr font__size__14 margin__top__bottom">Upload</p>
                                        </div>
                                        <a className="voice_mail_icon__altr ml-5 modal-trigger" href="#"
                                           onClick={(e) => {
                                               e.preventDefault();
                                               setVideoLibraryModal(true)
                                           }}>
                                    <span
                                        className="d-flex justify-content-center align-items-center">
                                        <i className="accent--color material-icons">video_library</i>
                                    </span>
                                            <p className="text-gray__altr font__size__14 margin__top__bottom">Library</p>
                                        </a>
                                        {
                                            videoLibraryModal &&
                                            <VideoLibraryModal loadSelectedVideoFile={loadSelectedVideoFile}
                                                               openVideoLibraryModal={videoLibraryModal}
                                                               setOpenVideoLibraryModal={setVideoLibraryModal}/>
                                        }
                                        {props.from !== 'quick_reply' &&
                                            <React.Fragment>
                                                <a className="voice_mail_icon__altr ml-5 modal-trigger" href="#"
                                                   onClick={(e) => {
                                                       e.preventDefault();
                                                       setSavedReplyModal(true)
                                                   }}>
                                            <span
                                                className="d-flex justify-content-center align-items-center">
                                                <i className="material-icons accent--color">folder</i>
                                            </span>
                                                    <p className="text-gray__altr font__size__14 margin__top__bottom">Quick
                                                        Reply</p>
                                                </a>
                                                {
                                                    savedReplyModal &&
                                                    <SavedReply openSavedReplyModal={savedReplyModal}
                                                                setOpenSavedReplyModal={setSavedReplyModal}
                                                                useMessageTemplate={useMessageTemplate}
                                                                templateType={CoreConstants.MESSAGE_TEMPLATE.VIDEO_EMAIL}/>
                                                }
                                            </React.Fragment>
                                        }
                                    </div>
                                    <div className="video-requirements">
                                        <h6>Video requirements:</h6>
                                        <ul>
                                            <li>Minimum length: 5 seconds / Maximum length: 2 minutes</li>
                                            <li>Maximum file size: 100mb</li>
                                            <li>Acceptable file formats: mp4</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col s12 m12 l6 xl6">
                                {
                                    state.videoStatus === CoreConstants.VIDEO_STATUS_WELCOME &&
                                    <div className="align-items-center px-3 py-2  video_email_bg_icon__alt white"
                                         style={{background: 'url(https://s3.us-east-1.amazonaws.com/pypepro/campaign/1/video-bg.gif) no-repeat center center/ cover'}}>
                                        <p className="overlay_text__alt">How would you like to create your video?</p>
                                    </div>
                                }

                                {
                                    state.videoStatus === CoreConstants.VIDEO_STATUS_WARNING &&
                                    <div
                                        className="align-items-center px-3 py-2 radius-10 video_email_bg_icon white video_warning">
                                        <h4>😑 Oh snap! We were not able to initialize your camera!</h4>
                                        <p className="text-justify">Your browser has not camera access. Please update to
                                            latest
                                            version, check camera and microphone permission, or change the browser</p>
                                        <p>
                                            See how to
                                            <a href="https://help.daily.co/en/articles/2528184-unblock-camera-mic-access-on-a-computer"
                                               target="_blank" rel="noreferrer"> enable </a>
                                            camera access or
                                            <a href="https://caniuse.com/#search=webrtc" target="_blank"
                                               rel="noreferrer"> check </a>
                                            if your browser is supported
                                        </p>
                                    </div>
                                }

                                {
                                    state.videoStatus === CoreConstants.VIDEO_STATUS_WEBCAM &&
                                    <div
                                        className="d-inline-flex align-items-center white radius-10 px-3 py-2 video_preview_side custom__video_preview_side ">
                                        <Webcam stopGif={stopGifRecord} startClick={startRecord}
                                                stopClick={stopRecord}/>
                                    </div>
                                }

                                {state.videoStatus === CoreConstants.VIDEO_STATUS_PREVIEW &&
                                    <div
                                        className="d-inline-flex align-items-center white radius-10 px-3 py-2 video_preview_side custom__video_preview_side ">
                                        {
                                            !state.isVideoSelected &&
                                            <LikeVideo videoObj={state.videoObj} selectVideo={selectVideo}/>
                                        }

                                        {state.willSaveAsTemplate &&
                                            <div className="likeArea">
                                                <div className="template_info">
                                                    <input name="title" autoFocus ref={props.templateTitle}
                                                           defaultValue={state.title}
                                                           placeholder="* Type your video title"/>
                                                    <textarea name="description" ref={props.templateDescription}
                                                              defaultValue={state.description}
                                                              placeholder="* Type a short description"/>
                                                    <Select style={{
                                                        width: '100%',
                                                        background: 'rgba(255,255,255,0.4)',
                                                        borderRadius: '5px',
                                                        height: '30px'
                                                    }} name="folderId" defaultValue={state.folderId}
                                                            ref={props.folderId}
                                                            onChange={(e) => setState({
                                                                ...state,
                                                                folderId: e.target.value
                                                            })}>
                                                        <MenuItem value='default'>Default Folder</MenuItem>
                                                        {renderFolderOptions()}
                                                    </Select>
                                                </div>
                                            </div>
                                        }
                                        {/* {(state.VideoLibraryModal || !state.VideoLibraryModal) && */}
                                        <RecordedVideo urlLink={state.videoUrl}/>
                                        {/* } */}
                                    </div>
                                }

                                {(state.likeStatus && props.from !== 'quick_reply') &&
                                    <FormControl component="fieldset">
                                        <FormControlLabel control={<Checkbox checked={state.saveAsTemplate}
                                                                             onChange={() => props.changeSaveAsTemplate()}
                                                                             name="save-as-template"/>}
                                                          label="Save as template ?"/>
                                    </FormControl>
                                }
                            </div>
                        </>
                }
            </div>

            <VideoValidationAlert
                open={showErrorAlert}
                data={errorData}
                onClose={() => {
                    setShowErrorAlert(false)
                }}
            />
        </div>
    );
}

const mapStateToProps = state => {
    return {
        userInfo: state.videoEmailReducer.userInfo,
        likeStatus: state.videoEmailReducer.likeStatus,
        willSaveAsTemplate: state.videoEmailReducer.willSaveAsTemplate,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        changeSaveAsTemplate: () => dispatch(changeSaveAsTemplate()),
        getVideoFolders: () => dispatch(getVideoFolders()),
        changeLikeStatus: (params) => dispatch(changeLikeStatus(params))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VideoEmail);