import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";
import { icons } from "../../icons.js";
import { RightSideStyle } from "../RightSideStyle.js";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import CustomAccordion, {
  CustomAccordionBody,
  CustomAccordionHeader,
} from "../../components/Accordion/CustomAccordion.js";
import DragableSection from "../DragableSection.js";
import { getContactTagList } from "../../../../actions/contactAction.js";
import { connect } from "react-redux";

const ContactInfo = (props) => {
  const [expand, setExpand] = useState(false);
  const { customScrollBar } = RightSideStyle();

  useEffect(()=>{
    if(expand && props.contact.id) {
      props.getContactTagList({
        contactId: props.contact.id,
      });
    }
  },[expand]);

  return (
    <Box>
      <CustomAccordion
        expand={expand}
        className="g-contact-details-collapsible"
      >
        <CustomAccordionHeader
          onClick={() => setExpand(!expand)}
          className={`${expand ? "g-collapsible-header-for-deal" : "g-collapsible-header"}  ${expand ? "expand" : ""}`}
        >
          <span class={`${expand ? "g-header-title-for-deal" : "g-header-title"}`}>
            <AccountCircleRoundedIcon
              style={{ marginRight: "8px!important", color:expand?'#fff':'rgb(24, 31, 72)' }}
            />{" "}
            Contact Info
          </span>
          <span className={`${expand?"g-custom-caret-for-deal":"g-custom-caret"}`}>{icons.caretIcon}</span>
        </CustomAccordionHeader>
        <CustomAccordionBody className="g-collapsible-body" expand={expand}>
          <Box className={customScrollBar}>
            <DragableSection />
          </Box>
        </CustomAccordionBody>
      </CustomAccordion>
    </Box>
  );
};

const mapStateToProps = (state) => {
  return {
    tagLoading: state.tagReducer.tagLoading,
    contact: state.addContactReducer.contact,
    contactTags: state.tagReducer.tags,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getContactTagList: (params) => dispatch(getContactTagList(params))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactInfo);
