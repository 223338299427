import { Box } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Address from "./address/Address";
import Email from "./email/Email";
import PhoneNumber from "./phoneNumbers/PhoneNumber";
import TagsForContact from "./tags/TagsForContact";
import ImportantDates from "./importantDates/ImportantDates";
import ImportantLinks from "./importantLinks/ImportantLinks";
import AdditionalInfo from "./AdditionalInfo/AdditionalInfo";
import { RightSideStyle } from "./RightSideStyle";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import LeadStatus from "./leadStatus/LeadStatus";

const getItemStyle = (isDragging, draggableStyle) => ({
    "& .lsv2DraggableItem": {
      borderRadius: 4,
      userSelect: "none",
      boxSizing: "border-box",
      background: isDragging ? "#fbfbfb" : "transparent",
      border: isDragging ? "1px solid rgba(0, 0, 0, 0.12)" : "",
      padding: isDragging ? 10 : "",
    },
  
    // styles we need to apply on draggable
    ...draggableStyle,
  });

const DragableSection = () => {
  const { customScrollBar } = RightSideStyle();
  const initialState = [
    { id: 1, component: <Address /> },
    { id: 2, component: <Email /> },
    { id: 3, component: <PhoneNumber /> },
    { id: 4, component: <TagsForContact />},
    { id: 5, component: <AdditionalInfo /> },
    { id: 6, component: <ImportantDates /> },
    { id: 7, component: <ImportantLinks /> },
    { id: 8, component: <LeadStatus /> },
 
  ];
  const [items, setItems] = useState(initialState);

  function saveToLocalStorage(key, data) {
    localStorage.setItem(key, JSON.stringify(data));
  }
  function getFromLocalStorage(key) {
    const data = localStorage.getItem(key);
    return data ? JSON.parse(data) : null;
  }
  const localOrder = getFromLocalStorage("contactLeftSideDragOrder");

  useEffect(() => {

    const defaultState = initialState.map((iState)=>(iState.id));

    if (localOrder && defaultState.length === localOrder.length) {
      const rearrangedState = localOrder.map((id) => initialState.find((item) => item.id === id));
      setItems(rearrangedState);
    }else if (localOrder && defaultState.length > localOrder.length) {
      const tempLocalOrder = [...localOrder];

      for (let i = 0; i < defaultState.length; i++) {
        let element = defaultState[i];
        if (!tempLocalOrder.includes(element)) {
          tempLocalOrder.push(element);
        }
      }
      const rearrangedState = tempLocalOrder.map((id) => initialState.find((item) => item.id === id));
      setItems(rearrangedState);
      saveToLocalStorage("contactLeftSideDragOrder", tempLocalOrder);
    }else {
      saveToLocalStorage("contactLeftSideDragOrder", defaultState);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const newBox = Array.from(items);
    const [draggedItem] = newBox.splice(result.source.index, 1);
    newBox.splice(result.destination.index, 0, draggedItem);
    setItems(newBox);

    const reorderedArray = newBox.map((item) => item.id);
    saveToLocalStorage("contactLeftSideDragOrder", reorderedArray);
  }
  return (
    <Box>
      <Box className={customScrollBar}>
    
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId='boxes'>
          {(provided) => (
            <Box
              sx={{ display: "flex", flexDirection: "column", }}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {items.map(({ id, component }, index) => (
                <Draggable key={id} draggableId={id.toString()} index={index}>
                  {(provided, snapshot) => (
                    <Box
                      sx={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      ref={provided.innerRef}
                      {...provided.dragHandleProps}
                      {...provided.draggableProps}
                    >
                      <div className='lsv2DraggableItem'>{component}</div>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>

      </Box>
    </Box>
  );
};

export default DragableSection;
