import React, { Component } from 'react';
import { Redirect, Route, BrowserRouter as Router, Switch } from 'react-router-dom';
import PublicLayoutRouter from "./PublicLayoutRouter";
import UserLayoutRouter from "./UserLayoutRouter";

// import components
import ForceLogin from "../components/auth/ForceLogin";
import ForgetPassword from "../components/auth/ForgetPassword";
import Login from "../components/auth/Login";
import SignUp from "../components/auth/SignUp";
import ContactCampaignUnsubscribe from "../components/ContactCampaignUnsubscribe/ContactEmailUnsubscribe";
import CustomPage from '../components/customMenuPage/CustomPage';
import DirectMailV2App from '../components/Direct-mail-v2/Index';
import HealthCheck from '../components/HealthCheck';
import {
    AppStore,
    Billing,
    BulkEmailBuilder,
    Calendar,
    Contact,
    Dashboard,
    DealStages,
    FormBuilder,
    FormBuilderV3,
    Inbox,
    InboxV2,
    InvoiceBuilder,
    LeadFlow,
    MapFinder,
    Marketing,
    PowerDialer,
    Profile,
    Report,
    Trigger,
    WebsiteBuilder
} from "../components/micromodulecomponent/common";
import NotFound from "../components/NotFound";
import Onboard from '../components/onboard/Onboard';
import Propagate from '../components/Propagate';
import ApiDoc from '../components/PublicPage/AppAuthentication/api-doc';
import AppAuthentication from '../components/PublicPage/AppAuthentication/AppAuthentication';
import ContactUs from '../components/PublicPage/ContactUs/ContactUs';
import PreviewVideoEmail from "../components/PublicPage/PreviewVideoEmail";
import PrivacyPolicy from '../components/PublicPage/privacyAndTcPages/PrivacyPolicy';
import TermsConditions from '../components/PublicPage/privacyAndTcPages/TermsAndConditions';
import RegisterV1 from '../components/registerV1/RegisterV1';
import CampaignSharePage from '../components/sharePages/CampaignSharePage';
import ConnectGoogleCallbackPage from "../components/sharePages/ConnectGoogleCallbackPage";
import ContactSharePage from '../components/sharePages/ContactSharePage';
import NylasActivatedPage from '../components/sharePages/NylasActivatedPage';
import NylasActivatedPageV2 from "../components/sharePages/NylasActivatedPageV2";
import TriggerSharePage from '../components/sharePages/TriggerSharePage';
import TiktokConnect from '../components/tiktok/TiktokConnect';
import TiktokIntegration from '../components/tiktok/TIktokIntegration';
import ZendirectValidation from '../components/zendirectValidation/ZendirectValidation';
import PublicLayoutRoute from "./PublicLayoutRouter";
import BulkEmailUnsubscribe from '../components/bulkEmailUnsubscribe/BulkEmailUnsubscribe';

class Routes extends Component {
    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/:type/get-started/v1" component={RegisterV1} />
                    <PublicLayoutRoute path="/:type/get-started" component={SignUp} />
                    <Route exact path="/whitelevelagency/user/force-login/:user_id" component={ForceLogin} />
                    <PublicLayoutRoute exact path="/user/login" component={Login} />
                    <PublicLayoutRoute exact path="/public-api/authorization-doc" component={AppAuthentication} />
                    <PublicLayoutRoute exact path="/public-api/doc" component={ApiDoc} />
                    <PublicLayoutRouter path="/contact-us" component={ContactUs} />
                    <Route exact path="/login" component={Login} />
                    <Route exact path="/privacy-policy" component={PrivacyPolicy} />
                    <Route exact path="/terms-and-conditions" component={TermsConditions} />
                    <Route exact path="/password-reset/:token?" component={ForgetPassword} />

                    <Route exact path="/campaign/:contactId/unsubscribe" component={ContactCampaignUnsubscribe} />
                    <Route exact path="/zendirect-validation" component={ZendirectValidation} />

                    <UserLayoutRouter exact path="/tiktok-connectpage" component={TiktokConnect} />
                    <UserLayoutRouter exact path="/tiktok-integration" component={TiktokIntegration} />
                    {/* <Route exact path="/tiktok-authentication" component={tik} /> */}

                    <Redirect from="/people/:id" to="/contacts/:id" />
                    <Redirect exact from="/users" to="/user/login" />
                    <Redirect exact from="/user" to="/user/login" />

                    <UserLayoutRouter exact path="/onboard" component={Onboard} />
                    <UserLayoutRouter exact path="/" component={Dashboard} />
                    <UserLayoutRouter exact path="/twilio-setup" component={Dashboard} />
                    <UserLayoutRouter exact path="/user/claim-contact/:id" component={Dashboard} />
                    <UserLayoutRouter exact path="/test-socket" component={Dashboard} />
                    <UserLayoutRouter exact path="/insurance-dashboard" component={Dashboard} />
                    <PublicLayoutRoute exact path="/onboarding" component={Dashboard} />
                    <UserLayoutRouter exact path="/get-started" component={Dashboard} />

                    <UserLayoutRouter exact path="/inbox" component={Inbox} />

                    {/* Custom menu route */}
                    <UserLayoutRouter exact path={'/user/custom-page'} component={CustomPage} />

                    {/* INBOXV2 Route*/}
                    <UserLayoutRouter exact path="/inbox-v2" component={InboxV2} />

                    {/*Marketing module*/}
                    <UserLayoutRouter exact path="/campaign" component={Marketing} />
                    <UserLayoutRouter exact path="/campaign-v2" component={Marketing} />
                    <UserLayoutRouter exact path="/campaign/details/:id" component={Marketing} />
                    <UserLayoutRouter exact path="/campaign/state/:campaignId/:tagId" component={Marketing} />
                    <UserLayoutRouter exact path="/campaign/template" component={Marketing} />
                    <UserLayoutRouter exact path="/campaign/view/:id?" component={Marketing} />
                    <UserLayoutRouter exact path="/campaign/allContacts/:campaign_id" component={Marketing} />
                    <UserLayoutRouter exact path="/quick-replies" component={Marketing} />
                    <UserLayoutRouter exact path="/quick-reply-add-template" component={Marketing} />
                    <UserLayoutRouter exact path="/quick-reply/:templateId" component={Marketing} />
                    <UserLayoutRouter exact path="/campaign/state/:campaignId/:tagId" component={Marketing} />
                    <UserLayoutRouter exact path="/campaign/registration" component={Marketing} />
                    <UserLayoutRouter exact path="/messaging/services" component={Marketing} />

                    {/*Trigger Module*/}
                    <UserLayoutRouter exact path="/triggers" component={Trigger} />
                    <UserLayoutRouter exact path="/trigger/settings/:triggerId" component={Trigger} />

                    {/* Contact routes */}
                    <UserLayoutRouter exact path="/contacts" component={Contact} />
                    <UserLayoutRouter exact path="/contacts/clean-list" component={Contact} />
                    <UserLayoutRouter exact path="/contacts/:id" component={Contact} />
                    <UserLayoutRouter exact path="/auto-dialer" component={Contact} />

                    {/* contact import */}
                    <UserLayoutRouter exact path={'/contacts-import'} component={Contact} />
                    <UserLayoutRouter exact path={'/campaign/import/:campaignId'} component={Contact} />
                    <UserLayoutRouter exact path={'/power-dialer/import/:powerDialerId'} component={Contact} />

                    <UserLayoutRouter exact path={'/batches'} component={Contact} />
                    <UserLayoutRouter exact path={'/batch-contacts/:id?/:title?'} component={Contact} />

                    <UserLayoutRouter exact path="/map-finder" component={MapFinder} />
                    <UserLayoutRouter exact path={'/data-export/csv-file'} component={Contact} />

                    <UserLayoutRouter exact path={'/power-dialer-test'} component={Contact} />

                    {/* inactive contact */}
                    <UserLayoutRouter exact path="/inactive-contact-list" component={Contact} />

                    {/* unsubscribe contact */}
                    <UserLayoutRouter exact path="/unsubscribe-contact-list" component={Contact} />

                    {/* bulk email campaign contact import */}
                    <UserLayoutRouter exact path="/bulk-email/campaign/import/:campaignUid" component={Contact} />

                    <UserLayoutRouter exact path="/import-contacts" component={Contact} />
                    <UserLayoutRouter exact path="/import-contact-summary" component={Contact} />
                    <UserLayoutRouter exact path="/import-contact-details" component={Contact} />

                    <UserLayoutRouter exact path="/import-spreadsheet-contact-summary" component={Contact} />
                    <UserLayoutRouter exact path="/import-spreadsheet-contact-details" component={Contact} />


                    {/*Calendar routes*/}
                    <UserLayoutRouter exact path="/activity-calendar" component={Calendar} />
                    <UserLayoutRouter exact path="/calendar-widgets" component={Calendar} />
                    <UserLayoutRouter exact path="/calendar-widget/:calendarWidgetId" component={Calendar} />

                    {/*Form Builder Routes*/}
                    <UserLayoutRouter exact path="/user/form-builder" component={FormBuilder} />
                    <UserLayoutRouter exact path="/user/form-builder-new" component={FormBuilder} />
                    <UserLayoutRouter exact path="/user/form-builder/:uniqueCode" component={FormBuilder} />
                    <UserLayoutRouter exact path="/user/form-entries/:uniqueCode" component={FormBuilder} />
                    <UserLayoutRouter exact path="/user/form/template" component={FormBuilder} />

                    <UserLayoutRouter exact path="/user/new/form-builder" component={FormBuilder} />
                    <UserLayoutRouter exact path="/user/new/form-builder/:uniqueCode" component={FormBuilder} />
                    <UserLayoutRouter exact path="/user/new/form-entries/:uniqueCode" component={FormBuilder} />

                    {/* New Form builder */}
                    <UserLayoutRouter exact path="/fbuilder/forms" component={FormBuilderV3} />
                    <UserLayoutRouter exact path="/fbuilder/statistics/:formId" component={FormBuilderV3} />
                    <UserLayoutRouter exact path="/fbuilder/submission/:formId" component={FormBuilderV3} />
                    <UserLayoutRouter exact path="/fbuilder/form/:formId" component={FormBuilderV3} fullScreen />
                    <PublicLayoutRouter exact path="/fbuilder/public/:base64Key" component={FormBuilderV3} />
                    <UserLayoutRouter exact path="/fbuilder/admin" component={FormBuilderV3} />
                    <UserLayoutRouter exact path="/fbuilder/admin/template/:formId" component={FormBuilderV3} fullScreen />
                    <UserLayoutRouter exact path="/fbuilder/files" component={FormBuilderV3} />
                    <UserLayoutRouter exact path="/fbuilder/report" component={FormBuilderV3} />

                    {/*Lead flow routes*/}
                    <UserLayoutRouter exact path="/leadFlow" component={LeadFlow} />
                    <UserLayoutRouter exact path="/leadFlow/settings/:leadFlowId" component={LeadFlow} />

                    {/* Deal Routes */}
                    <UserLayoutRouter exact path="/deal-stages" component={DealStages} />
                    <UserLayoutRouter exact path="/pipelines" component={DealStages} />
                    <UserLayoutRouter exact path="/deal/pipeline-logic" component={DealStages} />
                    <UserLayoutRouter exact path="/user/products" component={Profile} />

                    {/* Profile Routes */}
                    <UserLayoutRouter exact path={'/user/profile'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/trust-center'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/invoice'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/invoice-settings'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/telephony-service/phone-settings'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/telephony-service/trust-center'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/telephony-service/shaken-stir-cnam'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/telephony-service/call-outcome'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/telephony-service/call-logs'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/telephony-service/call-logs-details'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/general-settings'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/call-outcome'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/settings'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/profile/email-settings'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/profile/permission-settings'} component={Profile} />  {/* manage user v2 */}
                    <UserLayoutRouter exact path={'/user/profile/team'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/profile/snapshot'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/profile/snapshot-marketplace'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/profile/calendar-sync'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/profile/activity-logs'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/lists'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/tags'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/tags-v2'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/profile'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/activity'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/lead-group'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/packages'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/package/:id'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/map-orders'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/map-orders/:id'} component={Profile} />
                    <UserLayoutRouter exact path="/user/map-orders/v2/:id" component={Profile} />
                    <UserLayoutRouter exact path={'/user/phone-settings'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/mail-box'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/all-site-notification'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/all-lead-notification'} component={Profile} />
                    <UserLayoutRouter exact path={'/map/integration'} component={Profile} />
                    <UserLayoutRouter exact path={'/map/integration/payment'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/app-list'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/app-list/authorize/:clientID'} component={Profile} />
                    <UserLayoutRouter exact path={"/oauth2/authorize"} component={Profile} />
                    <UserLayoutRouter exact path={'/user/profile/calendar-sync-new'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/deal-report'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/connected-google-account'} component={Profile} />
                    <UserLayoutRouter exact path="/user/sales-reports" component={Profile} />
                    <UserLayoutRouter exact path="/user/policies" component={Profile} />

                    {/* Geo route */}
                    <UserLayoutRouter path={"/geo"} component={Profile} />
                    {/* Geo farming user Notifing route */}
                    <UserLayoutRouter path={"/geo-farming-user"} component={Profile} />
                    {/* custom fields */}
                    <UserLayoutRouter exact path={'/contact-custom-fields'} component={Profile} />
                    <UserLayoutRouter exact path={'/custom-fields'} component={Profile} />
                    {/* lead source */}
                    <UserLayoutRouter exact path={'/lead-source-type'} component={Profile} />
                    <UserLayoutRouter exact path={'/public-api'} component={Profile} />
                    {/* First Promoter Link */}
                    <UserLayoutRouter exact path={'/user/profile/first-promoter'} component={Profile} />

                    <UserLayoutRouter exact path={'/lead-permission-requests'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/profile/view-settings'} component={Profile} />

                    {/* custom-menu */}
                    <UserLayoutRouter exact path={'/user/profile/custom-menu'} component={Profile} />
                    <UserLayoutRouter exact path={'/user/contact-validation-report'} component={Profile} />

                    {/* Lookup */}
                    <UserLayoutRouter exact path="/user/lookup-validation" component={Profile} />

                    {/*Smart Webhook Report*/}
                    <UserLayoutRouter exact path="/user/smart-webhook-report" component={Profile} />
                    <UserLayoutRouter exact path="/user/smart-webhook-report/history" component={Profile} />

                    <UserLayoutRouter exact path="/user/profile/open-ai-integration" component={Profile} />


                    {/*Billing routes*/}
                    <UserLayoutRouter exact path="/billing" component={Billing} />
                    <UserLayoutRouter exact path="/cancellation-request" component={Billing} />
                    <UserLayoutRouter exact path="/cancellation-request/confirm" component={Billing} />

                    {/* Report Routes */}
                    <UserLayoutRouter exact path={'/business-report'} component={Report} />
                    <UserLayoutRouter exact path={'/goal/progress'} component={Report} />
                    <UserLayoutRouter exact path={'/goal/report/:goalId'} component={Report} />
                    <UserLayoutRouter exact path={'/bills'} component={Report} />
                    <UserLayoutRouter exact path={'/transactions'} component={Report} />
                    <UserLayoutRouter exact path={'/user-order/report'} component={Report} />
                    <UserLayoutRouter exact path={'/business-report-v2'} component={Report} />
                    <UserLayoutRouter exact path={'/call-logs'} component={Report} />
                    <UserLayoutRouter exact path={'/call-logs-full'} component={Report} />
                    <UserLayoutRouter exact path={'/power-dialer/call-logs'} component={Report} />
                    <UserLayoutRouter exact path={'/user/dnc-numbers'} component={Report} />

                    {/* App store routes */}
                    <UserLayoutRouter exact path="/integrations" component={AppStore} />
                    <UserLayoutRouter exact path="/user/calendly/token/get" component={AppStore} />
                    <UserLayoutRouter exact path="/integrations/calendly" component={AppStore} />
                    <UserLayoutRouter exact path="/integrations/facebook" component={AppStore} />
                    <UserLayoutRouter exact path="/user/facebook/connection/callback" component={AppStore} />
                    <UserLayoutRouter exact path="/integrations/app/:appId" component={AppStore} />

                    {/* Share page routes */}
                    <UserLayoutRouter exact path="/user/trigger/copy/:triggerId" component={TriggerSharePage} />
                    <UserLayoutRouter exact path="/user/copy-campaign/:campaignId" component={CampaignSharePage} />
                    <UserLayoutRouter exact path="/user/contact/copy-shared/:contactId" component={ContactSharePage} />
                    <UserLayoutRouter exact path="/user/redirect-nylas-hosted" component={NylasActivatedPage} />
                    <UserLayoutRouter exact path="/user/email-connect-callback" component={NylasActivatedPageV2} />
                    <Route exact path="/user/connect/google-authentication" component={ConnectGoogleCallbackPage} />

                    {/* Power Dialer Route */}
                    <UserLayoutRouter exact path="/power-dialer" component={PowerDialer} />
                    <UserLayoutRouter
                        exact
                        path="/power-dialer/general-scripts"
                        component={PowerDialer}
                    />

                    {/* Invoice Builder Route */}
                    <UserLayoutRouter exact path="/ibuilder/invoices" component={InvoiceBuilder} />
                    <UserLayoutRouter exact path="/ibuilder/invoice/:invoiceId" component={InvoiceBuilder} fullScreen />
                    <UserLayoutRouter exact path="/ibuilder/items" component={InvoiceBuilder} />
                    <UserLayoutRouter exact path="/ibuilder/reports" component={InvoiceBuilder} />
                    <UserLayoutRouter exact path="/ibuilder/export" component={InvoiceBuilder} />

                    {/* Public page urls */}
                    <PublicLayoutRouter path="/video-email/:videoId/:userId/:contactId?" component={PreviewVideoEmail} />
                    <PublicLayoutRouter path="/form/:uniqueId" component={FormBuilder} />
                    <PublicLayoutRouter path="/c/form/:uniqueId" component={FormBuilder} />
                    <Route path="/calendar/:uniqueId/:permalink" component={Calendar} />

                    <PublicLayoutRouter exact path="/invoice/payment/:agencyId/:invoiceId" component={AppStore} />

                    {/* ## WebsiteBuilder ## */}
                    <UserLayoutRouter exact path="/wbuilder/sites" component={WebsiteBuilder} />
                    <UserLayoutRouter exact path="/wbuilder/settings" component={WebsiteBuilder} />
                    <UserLayoutRouter exact path="/wbuilder/site/:websiteId" component={WebsiteBuilder} />
                    <UserLayoutRouter exact path="/wbuilder/page/:pageId" component={WebsiteBuilder} fullScreen />
                    <UserLayoutRouter exact path="/wbuilder/admin" component={WebsiteBuilder} />
                    <UserLayoutRouter exact path="/wbuilder/admin/site/:websiteId" component={WebsiteBuilder} />
                    <UserLayoutRouter exact path="/wbuilder/admin/page/:pageId" component={WebsiteBuilder} fullScreen />
                    <UserLayoutRouter exact path="/wbuilder/admin/section/:sectionId" component={WebsiteBuilder} fullScreen />
                    {/*FunnelBuilder*/}
                    <UserLayoutRouter exact path="/wbuilder/funnels" component={WebsiteBuilder} />
                    <UserLayoutRouter exact path="/wbuilder/funnel/:funnelId" component={WebsiteBuilder} fullScreen />
                    <UserLayoutRouter exact path="/wbuilder/funnel/page/:token" component={WebsiteBuilder} fullScreen />
                    <UserLayoutRouter exact path="/wbuilder/funnels/admin" component={WebsiteBuilder} />
                    <UserLayoutRouter exact path="/wbuilder/funnels/admin/funnel/:funnelId" component={WebsiteBuilder} fullScreen />
                    <UserLayoutRouter exact path="/wbuilder/funnel/admin/page/:token" component={WebsiteBuilder} fullScreen />

                    <UserLayoutRouter exact path="/bulk-email" component={BulkEmailBuilder} />

                    <UserLayoutRouter exact path="/bulk-email/copy/:campaignUid" component={BulkEmailBuilder} />
                    <UserLayoutRouter exact path="/bulk-email/report" component={BulkEmailBuilder} />
                    <UserLayoutRouter exact path="/bulk-email/report/:campaignUid" component={BulkEmailBuilder} />
                    <UserLayoutRouter exact path="/bulk-email/new-campaign" component={BulkEmailBuilder} />
                    {/* {healthCheck Route} */}
                    <Route exact path="/campaign/health-check" component={Marketing} />
                    <Route exact path="/contact/health-check" component={Contact} />
                    <Route exact path="/trigger/health-check" component={Trigger} />
                    <Route exact path="/app-store/health-check" component={AppStore} />
                    <Route exact path="/calendar/health-check" component={Calendar} />
                    <Route exact path="/form-builder/health-check" component={FormBuilder} />
                    <Route exact path="/report/health-check" component={Report} />
                    <Route exact path="/billing/health-check" component={Billing} />
                    <Route exact path="/profile/health-check" component={Profile} />
                    <Route exact path="/deal/health-check" component={DealStages} />
                    <Route exact path="/map-finder/health-check" component={MapFinder} />
                    <Route exact path="/lead-flow/health-check" component={LeadFlow} />
                    <Route exact path="/dashboard/health-check" component={Dashboard} />
                    <Route exact path="/inbox/health-check" component={Inbox} />
                    <Route exact path="/parent/health-check" component={HealthCheck} />


                    <UserLayoutRouter exact path="/directmail-send/:contactId" component={DirectMailV2App} />

                    <Route exact path="/propagate" component={Propagate} />
                    <Route exact path="/bulk-email/unsubscribe" component={BulkEmailUnsubscribe} />

                    <Route exact component={NotFound} />
                </Switch>
            </Router>
        );
    }
}

export default Routes;
