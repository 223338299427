import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import './login.css';
import Util from '../../helpers/Utils'
import { forgetPasswordRequest, resetPasswordRequest } from "../../api/forgetPasswordApi";
import { getCookie } from "../../helpers/Cookie";
import { fetchLoginPageData } from "../../api/loginApi";
import ListSkeleton from "../common/Skeletons/ListSkeleton";
import { Helmet } from "react-helmet";
import SimpleReactValidator from "simple-react-validator";

const ForgetPassword = (props) => {

    const history = useHistory();
    const { apiKey } = useSelector(state => state.loginReducer);
    const [email, setEmail] = useState('');
    const [token, setToken] = useState(props.match.params.token);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [loading, setLoading] = useState(true);
    const [agencyId, setAgencyId] = useState(null);
    const [, forceUpdate] = useState();
    const [error, setError] = useState({});
    const [success, setSuccess] = useState({});
    const [pageInfo, setPageInfo] = useState({
        logo: '',
        favIcon: '',
        agencyName: '',
        agencyAccentColor: '',
        privacyPolicy: '',
        termsAndConditions: '',
        agencyTextColor:'',
        agencyThemeColor:''
    });

    const validator = useRef(new SimpleReactValidator({
        className: 'text-danger',
        autoForceUpdate: { forceUpdate: forceUpdate },
        messages: {
            in: 'Passwords need to match!'
        }
    }));

    useEffect(() => {
        //Check access token
        const accessToken = getCookie(process.env.REACT_APP_ACCESS_TOKEN);
        if (accessToken.length !== 0) {
            history.push('/');
        }

        //Fetch agency id from url
        fetchLoginPageData().then(response => {
            setLoading(false);
            if (response.data.status) {
                setAgencyId(response.data.data.agencyId);
                setPageInfo({
                    ...pageInfo,
                    logo: response.data.data.agencyLogo,
                    favIcon: response.data.data.favIcon,
                    agencyName: response.data.data.agencyName,
                    agencyAccentColor: response.data.data.agencyAccentColor,
                    privacyPolicy: response.data.data.privacyPolicy,
                    termsAndConditions: response.data.data.termsAndConditions,
                    agencyTextColor: response.data.data.agencyTextColor,
                    agencyThemeColor: response.data.data.agencyThemeColor,
                });
            } else {
                setError({ message: <>Whops! something went wrong. Please reload the page</> })
                // window.showNotification("ERROR", "Opps! something went wrong. Please reload the page")
            }
        });
    }, [apiKey]);

    const handleSubmit = e => {
        setSuccess({ message: <></> });
        setError({ message: <></> });
        e.preventDefault();
        const formValid = validator.current.allValid();
        if (formValid) {
            let formData = new FormData();
            formData.append('email', email);
            formData.append('agencyId', agencyId);

            if (token) {

                formData.append('token', token);
                formData.append('password', password);
                formData.append('password_confirmation', confirmPassword);

                resetPasswordRequest(formData).then(response => {
                    if (response.data.status === 'success') {
                        setSuccess({ message: <>{response.data.html}</> })
                        setEmail('');
                        setPassword('');
                        setConfirmPassword('');
                        setTimeout(() => history.push('/login'), 2000)
                        // history.push('/login');
                    } else {
                        setError({ message: <>{response.data.html}</> })
                    }
                }).catch(error => {
                    Util.handleException(error);
                });
            } else {
                forgetPasswordRequest(formData).then(response => {
                    if (response.data.status === 'success') {
                        setSuccess({ message: <>{response.data.html}</> })
                        // window.showNotification("SUCCESS", response.data.html)
                        setEmail('');
                    } else {
                        setError({ message: <>{response.data.html}</> })
                        // window.showNotification("ERROR", response.data.html)
                    }
                }).catch(error => {
                    Util.handleException(error);
                })
            }
        } else {
            validator.current.showMessages();
            forceUpdate(true);
        }
    }

    return (
        <div className="h__100__percent" style={{background:pageInfo.agencyThemeColor}}>
            <Helmet>
                <link rel="icon" href={pageInfo.favIcon} />
                <title>{`Reset Password | ${pageInfo.agencyName}`}</title>
                <meta name="description" content="All-In-One Sales & Marketing Platform" />
                <meta name="keywords" content={`CRM, Automation, Sales, Marketing, ${pageInfo.agencyName}`} />
                <meta name="author" content={pageInfo.agencyName} />
            </Helmet>
            <div className="row h__100__percent">
                {
                    loading ? <PageLoading /> :
                        <div className="center-align h__100__percent singin_content">
                            <form className="singin_form" onSubmit={(e) => handleSubmit(e)}>
                                <div className="main_logo">
                                    <img className="login-page-logo" src={pageInfo.logo} alt="Logo" loading="lazy" />
                                </div>
                                {!token &&
                                    <div className="reset_password">
                                        <h2 style={{color:pageInfo.agencyTextColor}}>Reset your password</h2>
                                        <p style={{color:pageInfo.agencyTextColor}}>Please enter the email address you'd like your password reset information sent to.</p>
                                    </div>
                                }
                                {token &&
                                    <div className="reset_password">
                                        <h2 style={{color:pageInfo.agencyTextColor}}>Change your password</h2>
                                        <p style={{color:pageInfo.agencyTextColor}}>Please fill the form to change your password.</p>
                                    </div>
                                }
                                {
                                    typeof error.message != 'undefined' &&
                                    <div className="error_wrapper">
                                        <p className="text-danger">{error.message}</p>
                                    </div>
                                }
                                {
                                    typeof success.message != 'undefined' &&
                                    <div className="error_wrapper">
                                        <p className="text-success">{success.message}</p>
                                    </div>
                                }

                                <div className="row login_form">
                                    <div className="input-field login_form_input col s12">
                                        <input placeholder="Enter Your Email Address" id="email" type="email" className="validate" value={email} onChange={(e) => setEmail(e.target.value)} />
                                        <label style={{color:pageInfo.agencyTextColor}} htmlFor="email" className="active">Email Address</label>
                                        {validator.current.message('email', email, 'required|email')}
                                    </div>
                                </div>
                                {token &&
                                    <React.Fragment>
                                        <div className="input-field login_form_input col s12">
                                            <input placeholder="Enter New Password" id="password" type="password" className="validate" value={password} onChange={(e) => setPassword(e.target.value)} />
                                            <label htmlFor="password" className="active">New Password</label>
                                            {validator.current.message('password', password, 'required|min:6')}
                                        </div>
                                        <div className="input-field login_form_input col s12">
                                            <input placeholder="Confirm Password" id="confirm_password" type="password" className="validate" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                                            <label htmlFor="confirm_password" className="active">Confirm Password</label>
                                            {validator.current.message('confirmPassword', confirmPassword, `required|min:6|in:${password}`)}
                                        </div>
                                    </React.Fragment>
                                }
                                <button type="submit" className="login_btn" style={{ background: pageInfo.agencyAccentColor, cursor: 'pointer' }}>Reset your password</button>
                                <p style={{color:pageInfo.agencyTextColor}}>
                                    Go back to <Link to="/login" >Login Page</Link>
                                </p>
                            </form>
                            <div className="licence_text" style={{color:pageInfo.agencyTextColor}}>
                                <h6>{pageInfo.agencyName} {(new Date()).getFullYear()} - All Rights Reserved</h6>
                                {
                                    pageInfo.privacyPolicy !== null && pageInfo.privacyPolicy !== '' ?
                                        <a className="pr-4" href={pageInfo.privacyPolicy}>Privacy Policy</a> :
                                        <Link className="pr-4" to="/privacy-policy">Privacy Policy</Link>
                                }

                                {
                                    pageInfo.termsAndConditions != null && pageInfo.termsAndConditions !== '' ?
                                        <a href={pageInfo.termsAndConditions}>Terms And Conditions</a> :
                                        <Link to="/terms-and-conditions">Terms And Conditions</Link>
                                }
                            </div>
                        </div>
                }
            </div>
        </div>
    );
}

const PageLoading = () => (
    <div className="container h__100__percent">
        <div className="main_logo" style={{ margin: '0 auto', width: '250px' }}>
            <ListSkeleton width={250} height={100} />
        </div>
        <div className="main_logo" style={{ margin: '0 auto', width: '300px' }}>
            <ListSkeleton width={300} />
        </div>
        <br />
        <div className="main_logo" style={{ margin: '0 auto', width: '500px' }}>
            <ListSkeleton width={500} number={5} />
        </div>
        <br />
        <div className="main_logo" style={{ margin: '0 auto', width: '500px' }}>
            <ListSkeleton width={500} />
        </div>
        <div className="main_logo" style={{ margin: '0 auto', width: '250px' }}>
            <ListSkeleton width={250} />
        </div>
    </div>
)

export default ForgetPassword;
