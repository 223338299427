import { Box, InputAdornment } from "@material-ui/core";
import { Close } from "@material-ui/icons";
import SaveIcon from "@material-ui/icons/Save";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import BasicInput from "../../../custom/BasicInput";
import ApiList from "../../ApiList";
import useDebounce from "../../common/useDebounce";
import { EachTag } from "./EachTag";
import TagSkeleton from "./TagSkeleton";
import { TagsModalStyle } from "./TagsModalStyle";
import Utils from "../../../../helpers/Utils";

const Tags = ({
  contactId,
  setOpenModal,
  handleTagSubmit,
  subUserList,
  contactTags
}) => {
  const classes = TagsModalStyle();
  const [allTags, setAllTags] = useState([]);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [selectedTags, setSelectedTags] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [render, setRender] = useState(false);
  const debouncedSearch = useDebounce(search, 1000);
  const isUserOwnerOrAdmin = Utils.getAccountData("userIsOwner") || Utils.getAccountData("userIsAdmin");

  useEffect(()=>{
    if (contactTags) {
      setSelectedTags(contactTags.map((item)=>(item.tag_id)));
    }
  },[contactTags]);
  useEffect(() => {
    ApiList.getAllTags({ text: debouncedSearch }).then((res) => {
      let response = res.data;
      setAllTags(response || []);
      setLoading(false);
    });
  }, [debouncedSearch]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSearch = (event) => {
    setSearch(event.target.value);
    setLoading(true);
  };

  const renderAvailableTags = () => {
    return allTags.map((item, index) => (
      <EachTag
        subUserList={subUserList}
        item={item}
        selectedTags={selectedTags}
        onCheckChange={handleCheckChange}
        key={index}
      />
    ));
  };

  const handleCheckChange = (id) => {
    let newSelectItem = selectedTags;
    let itemIndex = selectedTags.indexOf(id);
    if (itemIndex >= 0) {
      newSelectItem.splice(itemIndex, 1);
    } else {
      newSelectItem.push(id);
    }
    setSelectedTags(newSelectItem);
    setRender(!render);
  };

  const handleCreateAndAssign = () => {
    if (submit || search === "") {
      return;
    }
    ApiList.addTagToContact({
      assign: false,
      contact_id: [contactId],
      new: true,
      tags: search,
    })
      .then((res) => {
        const { html, status } = res.data;

        if (status === "success") {
          window.showNotification("success", html);
          handleTagSubmit();
          setSelectedTags([]);
          setSearch("");
        } else {
          window.showNotification("error", html);
        }
      })
      .catch((error) => {
        console.error("Error creating and assigning tag:", error);
      });
  };
  const handleSubmit = () => {
    if (submit || selectedTags.length === 0) {
      return;
    }
    setSubmit(true);
    ApiList.addTagToContact({
      assign: true,
      contact_id: [contactId],
      new: false,
      tags: selectedTags,
    })
      .then((res) => {
        const { html, status } = res.data;

        if (status === "success") {
          window.showNotification("success", html);
          handleTagSubmit();
        } else {
          window.showNotification("error", html);
        }
      })
      .catch((error) => {
        console.error("Error assigning tags:", error);
      })
      .finally(() => {
        setSubmit(false);
      });
  };

  return (
    <>
      <Box className="mt-2">
        <div className="g-add-tag-content">
          <div className="g-tag-type-search">
            <p>Type to search or create tag</p>
            <BasicInput
              value={search}
              onChange={handleSearch}
              placeholder="Search or Create ..."
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon
                    style={{ color: "#656565", marginRight: "13px" }}
                  />
                </InputAdornment>
              }
            />
            {(search !== "" && isUserOwnerOrAdmin) && (
              <button
                className={classes.addTagConfirmButton}
                onClick={handleCreateAndAssign}
              >
                <span style={{ padding: "0px 10px" }}>
                  Create and assign with this :{" "}
                </span>
                <strong>{search.toUpperCase()}</strong>
              </button>
            )}
          </div>
          <div
            style={{ marginTop: "16px" }}
            className="awesome__scroll_bar g-all-tag-content"
          >
            {loading || submit ? (
              <TagSkeleton />
            ) : allTags && !allTags[0] ? (
              <p>No data found</p>
            ) : (
              renderAvailableTags()
            )}
          </div>
        </div>
      </Box>
      <Box
        position={"fixed"}
        bottom={0}
        bgcolor={"white"}
        width="485px"
        height="85px"
      >
        <div className={classes.actions}>
          <div className={classes.confirmButton} onClick={handleSubmit}>
            <div className={classes.button}>
              <SaveIcon />
              {submit
                ? "Assigning..."
                : selectedTags.length === 0
                ? "Assign Tag"
                : "Assign " + (selectedTags.length === 1 ? "Tag" : "Tags")}
            </div>
          </div>
          <div
            className={classes.closeButton}
            onClick={() => setOpenModal(false)}
          >
            <div className={classes.button}>
              <Close />
              Close
            </div>
          </div>
        </div>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    subUserList: state.addContactReducer.subUserList,
    contactTags: state.tagReducer.tags,
  };
};

export default connect(mapStateToProps)(Tags);
