import React, { useEffect, useRef, useState } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { useSelector } from "react-redux";
import Select from 'react-select';
import { sendMessage, fetchEmails } from "../../../api/contactApi";
import { ReactMultiEmail } from 'react-multi-email';
import CloseIcon from '@material-ui/icons/Close';
import CustomSelectBox from "./common/CustomSelectBox";
import SavedReply from "./common/SavedReply";
import CoreConstants from "./constants/CoreConstants";
import { image_upload_handler } from "../../../api/rootApi";
import Uploader from './common/Uploader/Uploader';

const Email = (props) => {
    const user = useSelector(state => state.inboxReducer.user);
    const contact = useSelector(state => state.inboxReducer.selectedContact);
    const [emailOption, setEmailOption] = useState([]);
    const [savedReplyModal, setSavedReplyModal] = useState(false);

    const [selectedEmail, setSelectedEmail] = useState(null);
    const [subject, setSubject] = useState('');
    const [emailBody, setEmailBody] = useState('');
    const [cc, setCC] = useState([]);
    const [bcc, setBCC] = useState([]);
    const [displayCC, setDisplayCC] = useState(false);
    const [displayBCC, setDisplayBCC] = useState(false);

    const [file, setFile] = useState(null);

    const [sending, setSending] = useState(false)

    const subjectInput = useRef(null);

    const messageInputRef = useRef(null)

    const [imageList, setImageList] = useState([])

    useEffect(() => {
        if (user.agency.email_provider !== 0 && user.email_provider == 1) {
            try {
                let email = JSON.parse(user.nylas_email_provider_info).email;

                setEmailOption([{
                    value: email,
                    label: email
                }]);

                setSelectedEmail({
                    value: email,
                    label: email
                });
            } catch (error) {
                setEmailOption([])
            }
        } else {
            loadEmail();
        }

        let content = '<br />' + (user.show_appointment_url == 1 ? (user.appointment_url ? user.appointment_url : '') : '');
        content += user.show_signature == 1 ? (user.signature ? user.signature : '') : '';

        setEmailBody(content);
    }, [])

    const sendEmail = () => {
        if (sending) {
            return
        }
        if (emailBody == '') {
            window.showNotification("WARNING", "Email message is required")
            return;
        }
        if (selectedEmail == null) {
            window.showNotification("WARNING", "No from email selected.")
            return;
        }
        setSending(true)
        // let formData = new FormData();

        // formData.append('contact_id', contact.id);
        // formData.append('origin', 3);
        // formData.append('message', emailBody);
        // formData.append('messageLevel', 'email');
        // formData.append('subject', subject);
        // formData.append('email', selectedEmail ? selectedEmail.value : null);
        // formData.append('cc', cc);
        // formData.append('bcc', bcc);

        // if (file) {
        //     formData.append('attached[]', file);
        // }

        let lists = imageList;
        let finalList = [];
        lists.forEach(function (list) {
            let name = list.name;
            if (name !== undefined) {
                finalList.push({
                    'file_name': list.file_original_name !== undefined ? list.file_original_name : name,
                    'file_url': name
                })
            }
        });
        // formData.append('imageList', finalList);
        // formData.append('attached[]', finalList);

        let formData = {
            contact_id: contact.id,
            origin: 3,
            message: emailBody,
            messageLevel: 'email',
            subject: subject,
            email: selectedEmail ? selectedEmail.value : null,
            cc: cc,
            bcc: bcc,
            attached: finalList
        }

        sendMessage(formData)
            .then(response => {
                setSending(false)
                if (response.data.status == 'success') {
                    window.appendConversation(response.data.conversation);
                    if (props.closeTab != undefined) {
                        props.closeTab()
                    }
                }
                else {
                    window.showNotification("ERROR", "Something went wrong. Try again later")
                }
            })
    }

    const handleBodyPersonalize = (event) => {
        setEmailBody(emailBody + event.target.value);
        // tinymce.activeEditor.insertContent(event.target.value);
        /* let newText = (event.target.value);

        setEmailBody(newText);

        bodyInput.current.selectionStart = bodyInput.current.selectionEnd = start + newText.length;
        bodyInput.current.focus(); */
    }

    const handleSubjectPersonalize = (event) => {
        let start = subjectInput.current.selectionStart;
        let end = subjectInput.current.selectionEnd;
        let text = subjectInput.current.value;
        let before = text.substring(0, start);
        let after = text.substring(end, text.length);
        let newText = (before + event.target.value + after);
        console.log(event.target.value);

        setSubject(newText);

        subjectInput.current.selectionStart = subjectInput.current.selectionEnd = start + newText.length;
        subjectInput.current.focus();
    }

    const loadEmail = () => {
        fetchEmails()
            .then(response => {
                let array = [];

                for (let email in response.data.data) {
                    array.push({
                        value: email,
                        label: email,
                    })
                }

                if (typeof array[0] !== 'undefined') {
                    setSelectedEmail(array[0]);
                }

                setEmailOption(array)
            });
    }

    const handleEditorChange = content => {
        setEmailBody(content);
    }

    const useMessageTemplate = data => {
        setSavedReplyModal(false);

        setSubject(data.subject);
        setEmailBody(data.message);
    }

    const emailUploadFileApi = `${process.env.REACT_APP_BACKEND_URL}/${process.env.REACT_APP_API_VERSION}/calendar/upload-email-file`;

    return (
        <div id="emailTab" className="col s12 active">
            <div className="chat__bottom_wrapper">
                <div className="catagory-area">
                    <div className="mb-0 d-flex">
                        <div className="my-0 mr-2 input-field number-input-field email-input-filed v2__width200px">
                            <svg width="14" height="8" viewBox="0 0 14 8" fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L13 1" stroke="#133159" strokeLinecap="round"
                                    strokeLinejoin="round" />
                            </svg>
                            <Select
                                options={emailOption}
                                isClearable={false}
                                value={selectedEmail}
                                onChange={(_selectedOption) => { setSelectedEmail(_selectedOption) }}
                                styles={{
                                    menu: provided => ({ ...provided, zIndex: 9999999 }),
                                    valueContainer: provided => ({
                                        ...provided,
                                        fontSize: '10px',
                                        // width: '121px',
                                        height: '38px'
                                    })
                                }}
                                placeholder="Select from this email"
                            />
                        </div>
                        <div className="subject__personalize_wrapper">
                            <div className="my-0 input-field subject-input-field">
                                <input type="text" placeholder="Subject" value={subject} ref={subjectInput} onChange={(e) => setSubject(e.target.value)} autoComplete={'nope'} />
                            </div>
                            <div className="my-0 input-field number-input-field personiliz-input-field-2">
                                <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M1 1L7 7L13 1" stroke="#133159" strokeLinecap="round" strokeLinejoin="round" /> </svg>
                                <CustomSelectBox handlePersonalize={handleSubjectPersonalize} />
                            </div>
                        </div>
                    </div>
                </div>

                {
                    displayCC && (
                        <div>
                            <div className="email-from d-flex align-items-center mr-2">
                                <h5 className="title">CC: </h5>
                                <ReactMultiEmail
                                    className="form-control"
                                    emails={cc}
                                    onChange={(_emails) => {
                                        setCC(_emails);
                                    }}
                                    getLabel={(
                                        cc,
                                        index,
                                        removeEmail,
                                    ) => {
                                        return (
                                            <div data-tag key={index}>
                                                {cc}
                                                <span data-tag-handle onClick={() => removeEmail(index)}>×</span>
                                            </div>
                                        );
                                    }}
                                />
                                <button className="btn btn-outline-danger close-receiver-button" onClick={(e) => {
                                    setCC([]);
                                    setDisplayCC(false);
                                }}><CloseIcon /></button>
                            </div>
                        </div>
                    )
                }

                {
                    displayBCC && (
                        <div>
                            <div className="email-from d-flex align-items-center mr-2">
                                <h5 className="title">BCC: </h5>
                                <ReactMultiEmail
                                    className="form-control"
                                    emails={bcc}
                                    onChange={(_emails) => {
                                        setBCC(_emails);
                                    }}
                                    getLabel={(
                                        bcc,
                                        index,
                                        removeEmail,
                                    ) => {
                                        return (
                                            <div data-tag key={index}>
                                                {bcc}
                                                <span data-tag-handle onClick={() => removeEmail(index)}>×</span>
                                            </div>
                                        );
                                    }}
                                />
                                <button className="btn btn-outline-danger close-receiver-button" onClick={(e) => {
                                    setBCC([]);
                                    setDisplayBCC(false);
                                }}><CloseIcon /></button>
                            </div>
                        </div>
                    )
                }

                <div className="tiny-mce">
                    <div className="mb-0 row">
                        <div className="col s12 sendemail d-none">
                            <ul className="p-0 m-0 right">
                                {
                                    !displayCC && (
                                        <li><a className="active" href="#" onClick={(e) => {
                                            e.preventDefault();
                                            setDisplayCC(true);
                                        }}>Add CC</a></li>
                                    )
                                }
                                {
                                    !displayBCC && (
                                        <li><a href="#" onClick={(e) => {
                                            e.preventDefault();
                                            setDisplayBCC(true);
                                        }}>Add BCC</a></li>
                                    )
                                }
                            </ul>
                        </div>
                        <div className="col s12 update__editor_wrapper">
                            {/* <Editor
                            apiKey='bicllzrgrdrhiix7wookyk6xg818xio48pu2dpyp7rtzcqtr'
                            value={emailBody}
                            cloudChannel='5-dev'
                            textareaName='email_reply'
                            init={{
                                height: 200,
                                menu: {
                                    favs: {
                                        title: 'My Favorites',
                                        items: 'code visualaid | searchreplace | emoticons'
                                    }
                                },
                                menubar: false,
                                convert_urls: false,
                                toolbar:
                                    'insertfile undo redo | styleselect | bold italic backcolor | \
                                    alignleft aligncenter alignright alignjustify | link | \
                                    bullist numlist outdent indent | removeformat | print preview media fullpage | help',
                                file_picker_types: 'file image media'
                            }}
                            onEditorChange={handleEditorChange}
                        /> */}
                            <Editor
                                apiKey='bicllzrgrdrhiix7wookyk6xg818xio48pu2dpyp7rtzcqtr'
                                onInit={(evt, editor) => messageInputRef.current = editor}
                                value={emailBody}
                                // initialValue={emailBody}
                                cloudChannel='5-dev'
                                textareaName='email_reply'
                                init={{
                                    height: 200,
                                    browser_spellcheck: true,
                                    // menubar: false,
                                    menu: {
                                        favs: { title: 'My Favorites', items: 'code visualaid | searchreplace | emoticons' }
                                    },
                                    menubar: 'favs file edit view insert format tools table help',
                                    plugins: [
                                        'advlist autolink lists link image imagetools hr charmap print preview anchor pagebreak',
                                        'searchreplace visualblocks visualchars code fullscreen media nonbreaking',
                                        'insertdatetime media table contextmenu paste code help wordcount template'
                                    ],
                                    convert_urls: false,
                                    toolbar:
                                        'insertfile undo redo | styleselect | bold italic backcolor | \
                                    alignleft aligncenter alignright alignjustify | link image | \
                                    bullist numlist outdent indent | removeformat | print preview media fullpage | help',
                                    imagetools_toolbar: 'rotateleft rotateright | flipv fliph | editimage imageoptions',
                                    imagetools_proxy: emailUploadFileApi,
                                    images_upload_handler: image_upload_handler,
                                    automatic_uploads: true,
                                    file_picker_types: 'file image media',
                                    forced_root_block: false
                                }}
                                onEditorChange={handleEditorChange}
                                id="email-setting-body-input-field"
                                placeholder="Compose your mail message"
                            />
                            <Uploader imagesList={(list) => setImageList(list)} updateComponent={imageList} />
                        </div>
                        {/* <div className="col s12">
                        <ImageUploadInput
                            callBack= {setFile}
                            filesLimit= {1}
                        />
                    </div> */}
                    </div>
                </div>

                <div className="send-button-area">
                    <div className="mb-0 row">
                        <div className="col s8">
                            <div className="mb-0 d-flex align-items-center">
                                <div className="my-0 mr-2 input-field number-input-field personiliz-input-field borderStyle1px v3_personalize alt">
                                    <svg width="14" height="8" viewBox="0 0 14 8" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1 1L7 7L13 1" stroke="#133159"
                                            strokeLinecap="round"
                                            strokeLinejoin="round" />
                                    </svg>
                                    <CustomSelectBox handlePersonalize={handleBodyPersonalize} />
                                </div>
                                <div className="quick-reply update_quick_reply">
                                    <a href="#" onClick={(e) => { e.preventDefault(); setSavedReplyModal(true) }}>
                                        <svg width="10" height="15" viewBox="0 0 10 15" fill="none"
                                            xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 0H10V15L5 11.9118L0 15V0Z" fill="#133159" />
                                        </svg>
                                        Use Quick Reply</a>
                                </div>
                                {
                                    savedReplyModal &&
                                    <SavedReply openSavedReplyModal={savedReplyModal} setOpenSavedReplyModal={setSavedReplyModal} useMessageTemplate={useMessageTemplate} templateType={CoreConstants.MESSAGE_TEMPLATE.EMAIL} />
                                }
                            </div>
                        </div>
                        <div className="col s4">
                            <div className="send-message-btn right p-relative">
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    sendEmail();
                                }}><i className="material-icons left">near_me</i>{sending ? 'Sending' : 'Send'}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Email;