const PERMISSION_CONST = {
    MODULES: {
        BILLING: 0,
        MANAGE_USER: 1,
        EXPORT_CONTACTS: 2,
        LEAD_OWNER_CHANGE: 3,
        GLOBAL_ITEM: 4,
        GOALS: 5,
        REPORT_ALL: 6,
        CALENDAR_MANAGE: 7,
    },
    PERMISSIONS: {
        READ: 2,
        WRITE: 4,
        DELETE: 8,
    }
};

export default PERMISSION_CONST;