import React, { useEffect, useState } from "react";
import {
    Slide,
    Backdrop,
    Modal, Typography,
    Divider,
} from "@material-ui/core";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import GlobalAddDealModalV3 from "./GlobalAddDealModalV3";
import { CloseIcon } from "./components/Icons";
import {createDealV2, getUserProductActiveList, updateDealV2, winDealWithProduct} from "./helpers/Api";
import { BasicButton, FooterWrapper } from "./GlobalAddDealModalStyles";
import Utils from "../../helpers/Utils";
import BootstrapTooltip from "../common/BootstrapTooltip";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2, 4, 3),
    },
    dealNameContainer: {
        height: '20px',
        borderRadius: '16px',
        backgroundColor: 'white',
        marginRight: '12px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px 16px',
        minWidth: '210px',

    },
    saveButton: {
        padding:"6px 10px !important",
        borderRadius:"4px !important",
        "&:focus, &:hover": {
          backgroundColor: "#0057f0 !important",
          transform: "translateY(-1px)",
        },
    },
}));

const CombinedDealAddModal = ({ open, onClose, data = null }) => {
    let estimateClosingDate = moment();
    const classes = useStyles();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [showContactSearch, setShowContactSearch] = useState(true);
    const [selectedContact, setSelectedContact] = useState(null);
    const [formData, setFormData] = useState({
        contact_stages_id: "",
        deal_value: 0,
        title: "",
        contact_id: "",
        user_pipeline_id: "",
        user_stage_id: "",
        estimate_closing_date: estimateClosingDate.format("YYYY-MM-DD"),
        user_contact_stage_products: []
    });
    const [errors, setErrors] = useState({
        title: "",
        deal_value: "",
        contact_id: "",
        user_pipeline_id: "",
        user_stage_id: "",
        estimate_closing_date: "",
        user_contact_stage_products: [
            {
                user_product_id: "",
                value: ""
            }
        ]
    });
    const [userProducts, setUserProducts] = useState([]);
    const [labels, setLabels] = useState({
        pipelineTitle: "",
        stageTitle: ""
    });
    const [dealMarkWin, setDealMarkWin] = useState(false);

    useEffect(() => {
        if (data && data.data) {
            let tempFormData = {
                contact_stages_id: "",
                deal_value: 0,
                title: "",
                contact_id: "",
                user_pipeline_id: "",
                user_stage_id: "",
                estimate_closing_date: estimateClosingDate.format("YYYY-MM-DD"),
                user_contact_stage_products: []
            };

            if (data.data) {
                if (data.edit !== undefined && data.edit !== null) {
                    setIsEdit(data.edit);
                }else {
                    setIsEdit(false);
                }

                if (data.mark_win !== undefined && data.mark_win !== null){
                    setDealMarkWin(data.mark_win);
                }else {
                    setDealMarkWin(false);
                }

                let callData = data.data;
                if (callData) {
                    if (callData.title !== undefined && callData.title !== null) {
                        tempFormData.title = callData.title;
                    }

                    if (callData.contact_id !== undefined && callData.contact_id !== null) {
                        tempFormData.contact_id = callData.contact_id;
                        setShowContactSearch(false);
                    }

                    if (callData.pipelineId !== undefined && callData.pipelineId !== null) {
                        tempFormData.user_pipeline_id = callData.pipelineId;
                    }

                    if (callData.user_stage_id !== undefined && callData.user_stage_id !== null) {
                        tempFormData.user_stage_id = callData.user_stage_id;
                    }

                    if (callData.contact_stages_id !== undefined && callData.contact_stages_id !== null) {
                        tempFormData.contact_stages_id = callData.contact_stages_id;
                    }

                    if (callData.deal_value !== undefined && callData.deal_value !== null) {
                        tempFormData.deal_value = callData.deal_value;
                    }

                    if (callData.estimate_closing_date !== undefined && callData.estimate_closing_date !== null) {
                        let estimateClosingDate = moment(callData.estimate_closing_date);
                        if (estimateClosingDate.isValid()) {
                            estimateClosingDate = estimateClosingDate.format("YYYY-MM-DD");
                        }
                        tempFormData.estimate_closing_date = estimateClosingDate;
                    }

                    if (callData.user_contact_stage_products !== undefined && callData.user_contact_stage_products !== null) {
                        tempFormData.user_contact_stage_products = callData.user_contact_stage_products;
                        tempFormData.deal_value = callData.user_contact_stage_products[0].value;
                    }
                }
            }

            setFormData(tempFormData);
        }
    }, [data]);

    useEffect(() => {
        if (open) {
            getProducts();
        }
    }, [open]);

    const getProducts = async () => {
        try {
            const resp1 = await getUserProductActiveList();
            if (resp1.success) {
                setUserProducts(resp1.data);
            } else {
                setUserProducts([]);
            }
        } catch (err) {
            console.log(err);
        }
    }

    const sumProductValue = () => {
        let dealValue = 0;

        formData.user_contact_stage_products.forEach((selectedProduct) => {
            const selectedValue = parseFloat(selectedProduct.value);
            if (!isNaN(selectedValue)) {
                dealValue += selectedValue;
            }
        });

        return dealValue;
    };

    const closeModal = () => {
        setTimeout(() => {
            handleResetFormData();
            onClose();
        }, 500);
    };

    const handleCloseModal = () => {
        handleResetFormData();
        if (isEdit) {
            setIsEdit(false);
        }
        onClose();
    };

    const handleResetFormData = () => {
        setSelectedContact(null);
        setShowContactSearch(true);
        setIsEdit(false);
        setFormData({
            title: "",
            contact_id: "",
            contact_stages_id: "",
            user_stage_id: "",
            deal_value: 0,
            user_pipeline_id: "",
            estimate_closing_date: estimateClosingDate.format("YYYY-MM-DD"),
            user_contact_stage_products: []
        });
    };

    const handleChangeFormData = (key, value, index = null) => {

        if (key === "deal_value" && typeof value === "string" && value.trim() !== "") {
            if (value.length > 10) {
                return;
            }

            value = parseFloat(value)

            const selectedValue = parseInt(value);
            if (isNaN(selectedValue)) {
                return;
            }
        }

        setFormData((prevState) => ({ ...prevState, [key]: value }));
        if (errors[key]) {
            if (index !== null) {
                if (key === "user_contact_stage_products") {
                    let oldErrors = [...errors.user_contact_stage_products];
                    oldErrors[index].user_product_id = "";
                    oldErrors[index].value = "";
                    setErrors((prevState) => ({ ...prevState, [key]: oldErrors }));
                }
            } else {
                setErrors((prevState) => ({ ...prevState, [key]: "" }));
            }
        }
    };

    const handleSubmit = async () => {
        let tempErrors = {
            title: "",
            contact_id: "",
            deal_value: "",
            user_stage_id: "",
            user_pipeline_id: "",
            estimate_closing_date: "",
            user_contact_stage_products: [
                {
                    user_product_id: "",
                    value: ""
                }
            ]
        };

        let isHot = false;

        // if (formData.title.trim() === "") {
        //     tempErrors.title = "Deal name is required";
        //     isHot = true;
        // }

        if (formData.contact_id === "") {
            tempErrors.contact_id = "Contact is required";
            isHot = true;
        }

        if (formData.user_pipeline_id === "") {
            tempErrors.user_pipeline_id = "Pipeline is required";
            isHot = true;
        }

        if (formData.user_stage_id === "") {
            tempErrors.user_stage_id = "Deal stage is required";
            isHot = true;
        }

        if (formData.estimate_closing_date === "") {
            tempErrors.estimate_closing_date = "Estimated close date is required";
            isHot = true;
        }

        if (formData.user_contact_stage_products[0]) {
            let tempUserContactStageProductErrors = [...errors.user_contact_stage_products];

            formData.user_contact_stage_products.forEach((userStateProduct, index) => {

                if (!tempUserContactStageProductErrors[index]) {
                    tempUserContactStageProductErrors[index] = {
                        user_product_id: "",
                        value: ""
                    };
                }

                if (userStateProduct?.user_product_id === "") {
                    tempUserContactStageProductErrors[index].user_product_id = "Product is required";
                    isHot = true;
                }

                if (userStateProduct?.value === "") {
                    tempUserContactStageProductErrors[index].value = "Value is required";
                    isHot = true;
                }
            });

            tempErrors.user_contact_stage_products = tempUserContactStageProductErrors;
        }

        if (isHot) {
            setErrors(tempErrors);
            window.showNotification("error", "Please ensure all required fields in the form are completed correctly");
            return;
        }

        try {
            setIsSubmitting(true);
            let response;

            let payload = { ...formData };

            if (isEdit) {
                response = await updateDealV2(payload);
            } else {
                if (dealMarkWin){
                    let modPayload = {
                        product_data: {
                            contact_id: formData.contact_id,
                            user_product_id: data.user_product_id,
                            value: data.value,
                        },
                        deal_data: {
                            contact_id: formData.contact_id,
                            contact_stages_id: formData.contact_stages_id,
                            deal_value: formData.deal_value,
                            estimate_closing_date: formData.estimate_closing_date,
                            title: formData.title,
                            user_contact_stage_products: formData.user_contact_stage_products,
                            user_pipeline_id: formData.user_pipeline_id,
                            user_stage_id: formData.user_stage_id
                        },
                        policy_data: null,
                    };

                    response = await winDealWithProduct(modPayload);
                }else {
                    response = await createDealV2(payload);
                }
            }

            if (response?.success) {
                if (data?.callback) {
                    let backData = { pipelineId: formData.user_pipeline_id, stageId: formData.user_stage_id, ...labels };

                    if (response.data.id !== undefined && response.data.id !== null) {
                        backData.contact_stage_id = response.data.id;
                        backData.contact_stage = response.data;
                        backData.contact_stage_products = mergeArraysById(formData.user_contact_stage_products, userProducts);
                    }

                    data.callback(backData);
                }
                window.showNotification("success", response?.message);
                handleCloseModal();
            } else {
                window.showNotification("error", response?.message || "Something went wrong");
            }
        } catch (err) {
            window.showNotification("error", "Something went wrong");
            console.log(err);
        } finally {
            setIsSubmitting(false);
        }
    };

    const getContactLabel = (name, number, email) => {
        name = name ? name.trim() : '';
        number = number ? number.trim() : '';
        email = email ? email.trim() : '';
        console.log(email)
        if (name === '' || name === 'null null') {
            if (number === '') {
                if (email === '') {
                    return '[No Name]';
                } else {

                    return email.length > 25 ? email.substring(0, 22) + ' ...' : email;
                }
            } else {
                return number;
            }
        }
        return name.length > 25 ? name.substring(0, 22) + ' ...' : name;
    };

    const getTooltipTitle = (name, number, email) => {
        name = name ? name.trim() : '';
        number = number ? number.trim() : '';
        email = email ? email.trim() : '';
        console.log(email)
        if (name === '' || name === 'null null') {
            if (number === '') {
                if (email === '') {
                    return '[No Name]';
                } else {

                    return email;
                }
            } else {
                return number;
            }
        }
        return name;
    };

    const mergeArraysById = (selected, list) => {
        return selected.map(selectedItem => {
            const listItem = list.find(item => item.id === selectedItem.user_product_id);
            return listItem ? { ...listItem, ...selectedItem } : selectedItem;
        });
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={handleCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 1000,
            }}
            onBackdropClick={closeModal}
            ModalProps={{
                disableEnforceFocus: true,
                disableAutoFocus: true,
            }}
        >
            <Slide in={open} direction="left">
                <div className="globalModalWrapper cardBordcastTab global__add__deal__wrapper">
                    <div id="globalModal">
                        <div
                            className="globalModalTitle d-flex justify-content-between align-items-center"
                            style={{ backgroundColor: "#3C7EF3" }}
                        >
                            <div className="sub_title" style={{ width: "100%" }}>
                                {isEdit ? (
                                    <h2 style={{ color: "#fff", whiteSpace: "nowrap", fontWeight: "600", fontSize: '22px' }}>Edit Deal</h2>
                                ) : (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "10px",
                                        }}
                                    >
                                        <h2 style={{ color: "#fff", whiteSpace: "nowrap", fontWeight: "600", fontSize: '22px' }}>
                                            {" "}
                                            Add a new Deal
                                        </h2>

                                        <div style={{ width: "100%" }}>
                                            <Divider width="96%" style={{ background: "white" }} />
                                        </div>
                                    </div>
                                )}
                            </div>
                            {
                                selectedContact && (<div
                                    className={classes.dealNameContainer}
                                >
                                    <BootstrapTooltip title={getTooltipTitle(selectedContact.fullName, selectedContact.number, selectedContact.email)} arrow placement="top">
                                        <Typography style={{ color: 'black', fontSize: '14px', height: 'unset !important' }}>{getContactLabel(selectedContact.fullName, selectedContact.number, selectedContact.email)}</Typography>
                                    </BootstrapTooltip>
                                </div>
                                )}
                            <div
                                onClick={handleCloseModal}
                                className="share_campaign_cross_button d-flex justify-content-center align-items-center modal-close"
                            >
                                <CloseIcon />
                            </div>
                        </div>

                        <div
                            className="modalBody"
                            style={{ position: "relative", height: "calc(100% - 140px)" }}
                            id={"modal___body___container___id"}
                        >
                            <GlobalAddDealModalV3
                                isEdit={isEdit}
                                showContactSearch={showContactSearch}
                                formData={formData}
                                errors={errors}
                                handleChangeFormData={handleChangeFormData}
                                setLabels={setLabels}
                                selectedContact={selectedContact}
                                setSelectedContact={setSelectedContact}
                                userProducts={userProducts}
                                dealMarkWin={dealMarkWin}
                            />
                        </div>

                        <FooterWrapper>
                            <Typography variant="h5" style={{ fontSize: '22px', fontWeight: "600" }}>
                                Deal Value: $ {Utils.formatCurrency(formData.deal_value)}
                            </Typography>
                            <BasicButton
                                className={classes.saveButton}
                                disabled={isSubmitting}
                                onClick={handleSubmit}
                                style={{ width: "90px" }}
                                variant="contained"
                                disableElevation
                            >
                                {isSubmitting ? "Saving..." : "Save"}
                            </BasicButton>
                        </FooterWrapper>
                    </div>
                </div>
            </Slide>
        </Modal>
    );
}

export default CombinedDealAddModal;