import { all, takeEvery, call, put } from 'redux-saga/effects';
import * as ACTION from '../constants/contactActionTypes'
import {getContactTagList} from "../api/contactApi";

function* getContactTagWatcher() {
    yield takeEvery(ACTION.GET_CONTACT_TAG, attemptGetContactTag)
}

function* attemptGetContactTag(action) {
    try {
        const response = yield call(getContactTagList, action.payload);
        let responseData = response.data;
        if (responseData.success) {
            yield put({type: ACTION.SET_CONTACT_TAG, payload: responseData.data});
        } else {
            yield put({type: ACTION.SET_CONTACT_TAG, payload: null});
        }
    } catch (error) {
        console.log(error);
        yield put({type: ACTION.SET_CONTACT_TAG, payload: null});

    }
}

export default function* tagMiddleware() {
    yield all([
        getContactTagWatcher()
    ])
}