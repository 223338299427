import { makeStyles } from "@material-ui/core";

export const TagsModalStyle = makeStyles((theme) => ({
  actions: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    padding: "20px",
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0px 10px",
    gap: 5,
  },
  closeButton: {
    height: "40px",
    width: "100px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    backgroundColor: "#ef244a",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "&:focus, &:hover": {
      backgroundColor: "#ef244a",
      transform: "translateY(-1px)",
    },
  },
  confirmButton: {
    height: "40px",
    width: "150px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    backgroundColor: "#346fee",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    "&:focus, &:hover": {
      backgroundColor: "#346fee",
      transform: "translateY(-1px)",
    },
  },
  addTagConfirmButton: {
    height: "40px",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    backgroundColor: "#346fee",
    color: "#fff",
    display: "flex",
    alignItems: "center",
    margin:"10px 0px",
    "&:focus, &:hover": {
      backgroundColor: "#346fee",
      transform: "translateY(-1px)",
    },
  },
}));
