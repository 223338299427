export const searchIcon = ({ width = 20, height = 21 }) => {
    return (
      <svg
        width={width}
        height={height}
        viewBox="0 -1 20 20"
        fill="#9ea0a8"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M15.0252 14.3469L18.5943 17.9152L17.4152 19.0943L13.8469 15.5252C12.5191 16.5895 10.8677 17.1684 9.16602 17.166C5.02602 17.166 1.66602 13.806 1.66602 9.66602C1.66602 5.52602 5.02602 2.16602 9.16602 2.16602C13.306 2.16602 16.666 5.52602 16.666 9.66602C16.6684 11.3677 16.0895 13.0191 15.0252 14.3469ZM13.3535 13.7285C14.4111 12.6409 15.0017 11.183 14.9993 9.66602C14.9993 6.44268 12.3885 3.83268 9.16602 3.83268C5.94268 3.83268 3.33268 6.44268 3.33268 9.66602C3.33268 12.8885 5.94268 15.4993 9.16602 15.4993C10.683 15.5017 12.1409 14.9111 13.2285 13.8535L13.3535 13.7285Z"
          fill={0.4}
          fillOpacity="#0D1126"
        />
      </svg>
    );
  };